import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from './images/challenge.svg'
// import { ReactComponent as MenuIcon } from "./images/menu.svg";
import { ReactComponent as Login } from './images/user_icon.svg'
import './App.scss'
import * as URLS from './components/RouteList'
// bootstrap includes
import { Container, Row, Col, Navbar, Offcanvas } from 'react-bootstrap'

// My Component defenitions
import NavMenu from './PageLinks'

const LoginBox = () => (
  <div id="center-header">
    <a href={'/dealer_portal/dealer_login.htm'} className="login">
      <Login className="person" />
      <p className="login-txt">Login</p>
    </a>
  </div>
)

const DesktopNavBootstrap = () => (
  <Container fluid>
    <Row>
      <Col xs={2}>
        <Link to="/">
          <Logo className="logo" />
        </Link>
      </Col>
      <Col>
        <div id="center-header">
          <NavMenu classMod="" isExpanded={false} />
        </div>
      </Col>
      <Col xs={2}>
        <LoginBox />
      </Col>
    </Row>
  </Container>
)

const MobileNav = () => (
  <Container fluid>
    <Row>
      <Col xs={4}>
        <div id="center-header">
          <OffCanvasMenu />
        </div>
      </Col>
      <Col>
        <Logo className="logo" />
        <div id="center-header">
          <h1 className="cmc-name">CMC</h1>
        </div>
      </Col>
      <Col xs={4}>
        <LoginBox />
      </Col>
    </Row>
  </Container>
)

const OffCanvasMenu = () => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Navbar bg="none" expand={false} expanded={expanded}>
      <Container fluid>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar"
          id="exp-menu"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          onHide={() => setExpanded(!expanded)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div id="drop-nav">
              <NavMenu
                classMod="flex-column"
                isExpanded={true}
                setExpanded={setExpanded}
              />
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

const Navigation = (): JSX.Element => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const breakpoint = 992
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
  return dimensions.width < breakpoint ? <MobileNav /> : <DesktopNavBootstrap />
}

export default Navigation
