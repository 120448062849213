import React, { useEffect } from 'react'
import * as utls from '../../utilities/utls'
import '../../App.scss'
import './Home.scss'

// bootstrap includes
import { Carousel, Row, Col, Container } from 'react-bootstrap'

// images
import slide0_m from '../../images/m_pfs_expo.png'
import slide0_d from '../../images/d_pfs_expo.png'
import slide1_m from '../../images/m_tc_knife.png'
import slide1_d from '../../images/d_tc_knife.png'
import slide2_m from '../../images/m_champ_185_new.png'
import slide2_d from '../../images/d_champ_185_new.png'
import slide3_m from '../../images/m_building.png'
import slide3_d from '../../images/d_building.png'

import { ReactComponent as Arrow } from '../../images/arrow.svg'
import m_cmt from '../../images/m_CMT-330.png'
import { Link } from 'react-router-dom'

const Home = (): JSX.Element => {
  const slide0_imgalt = 'TECH EXPO 2024'
  const slide1_imgalt = 'Side Knife'
  const slide2_imgalt = 'CMC Campus'
  const slide3_imgalt = 'CHAMP 185 Release'

  const slide0_url = 'https://www.pfsgraphics.com/techexpo'

  useEffect(() => {
    console.log('Rendering <Home />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Home">
      <Carousel>
        {/*
          <Carousel.Item interval={utls.CAROUSEL_INTERVAL}>
            <div className="show_slide">
              <a href={slide0_url} target="_blank" rel="noreferrer">
                <picture>
                  <source
                    media={'(min-width: ' + utls.LARGE + ')'}
                    srcSet={slide0_d}
                  />
                  <img
                    className="d-block w-100"
                    src={slide0_m}
                    alt={slide0_imgalt}
                  />
                </picture>
              </a>
            </div>
            <Carousel.Caption id="cc0">
              <h2> </h2>
            </Carousel.Caption>
          </Carousel.Item>
        */}
        <Carousel.Item interval={utls.CAROUSEL_INTERVAL}>
          <picture>
            <source
              media={'(min-width: ' + utls.LARGE + ')'}
              srcSet={slide3_d}
            />
            <img className="d-block w-100" src={slide3_m} alt={slide3_imgalt} />
          </picture>
          <Carousel.Caption id="cc3">
            <h2>
              Celebrating Over
              <span> 150 </span>
              Years of Innovation
            </h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={utls.CAROUSEL_INTERVAL}>
          <picture>
            <source
              media={'(min-width: ' + utls.LARGE + ')'}
              srcSet={slide1_d}
            />
            <img className="d-block w-100" src={slide1_m} alt={slide1_imgalt} />
          </picture>
          <Carousel.Caption id="cc1">
            <h2>Quality Cutting Every Time</h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={utls.CAROUSEL_INTERVAL}>
          <div className="champ_slide">
            <picture>
              <source
                media={'(min-width: ' + utls.LARGE + ')'}
                srcSet={slide2_d}
              />
              <img
                className="d-block w-100"
                src={slide2_m}
                alt={slide2_imgalt}
              />
            </picture>
          </div>
          <Carousel.Caption id="cc2">
            <h3>Introducing the CHAMP 185</h3>
            <p>Cut Smarter with the CHAMP 185Pro</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div id="b1">
        <h1>The Challenge Machinery Company</h1>
        <p>
          The Challenge Machinery Company has been an innovator in cutting
          equipment for over 150 years. Since producing our very first paper
          cutter in 1887, other achievements include the first paper drilling
          machine, the first hydraulic paper cutter, the first programmable
          paper cutter, and more recently the first fully automated programmable
          three-knife book trimmer.
        </p>
      </div>
      <div id="b2">
        <Container fluid>
          <Row className="justify-content-center">
            <Col>
              <h1>Cutting Today</h1>
              <p>
                Material graphics in today's world requires fast and efficient
                equipment that can easily adapt to any job. Our company always
                keeps this in mind when designing our products. Challenge's
                cutting edge book trimmer, the CMT 330 TC, is designed with a
                'book-of-one' approach to automatically adjust cutting size for
                any unique book size. This process streamlines the book trimming
                and can lead to a higher throughput on any printing line.
              </p>
              <p>
                Learn more about the&nbsp;
                <span>
                  <Link to="/products/book-trimmers/cmt-330-tc" id="cmt-link">
                    CMT-330 Trimmer <Arrow />
                  </Link>
                </span>
              </p>
            </Col>
            <Col xs={6} md={6} lg={4} className="d-none d-md-block">
              <img src={m_cmt} alt="CMT-330" width="100%" />
            </Col>
          </Row>
        </Container>
      </div>
      <div id="b3">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs={3} md={4} lg={4} className="d-none d-sm-block"></Col>
            <Col>
              <div id="b3-content">
                <h1>Pioneers in Paper Cutting</h1>
                <p>
                  Established in 1870, The Challenge Machinery Company has
                  introduced a vast array of machines to the paper cutting and
                  handling industry. With over a century and a half of knowledge
                  and expertise, our engineers have developed the pinnacle of
                  paper cutting and book trimming technology. Our commitment to
                  the field has resulted in streamlined efficiency without the
                  sacrifice of quality or cost. Quality and durability
                  unmatched, we guarantee support for all our industrial cutters
                  and trimmers for minimum of 15 years past their end of
                  production.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Home
