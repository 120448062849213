// Dependencies

import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// Web-Pages
import App from './App'
// Home
import Home from './pages/Home/Home'
// Support
import Support from './pages/Support/Support'
import MachineInfo from './pages/Support/Machine_Info/Machine_Info'
import SafetyInfo from './pages/Support/Safety_Info/Safety_Info'
import SoftwareUpdates from './pages/Support/Software_Updates/Software_Updates'
import SoftwareVersions from './pages/Support/Software_Updates/Software_Versions'
import Parts from './pages/Support/Parts/Parts'
import Warranty from './pages/Support/Warranty/Warranty'
import Msds from './pages/Support/MSDS/Msds'
// Dealers
import Dealers from './pages/Dealers/Dealers'
// About
import About from './pages/About/About'
import History from './pages/About/History/History'
import Mission from './pages/About/Mission/Mission'
// Products
import Products, {
  ProductDisplay,
  ProductTypes
} from './pages/Products/Products'
import EProduct from './pages/Products/Components/ProductTypes'
// Contact
import Contact from './pages/Contact/Contact'
// Information
import Privacy from './pages/Information/Privacy'
import Terms from './pages/Information/Terms'
import Cookies from './pages/Information/Cookies'
// General URLS
import * as URLS from './components/RouteList'

const NoMatchError = () => {
  useEffect(() => {
    console.log('Rendering <Contact />')
    window.scrollTo(0, 0)
  }, [])
  return (
    <h3 style={{ textAlign: 'center', margin: '2em' }}>404 - Page not found</h3>
  )
}

export type ProductTypeId = {
  dispName: string
  apiType: string
  id: EProduct
  path: string
  description: string
  s_description: string
}

const CUTTER_DESC =
  'Since producing its first paper cutter in 1887, Challenge has been recognized as the industry leader in providing innovative paper cutters that are built to last. Our current line of paper cutters continue the long legacy of Challenge safety, productivity, innovation, and durability.'
const TRIMMER_DESC =
  'Since the introduction of the Challenge CMT-330 at PRINT ’01, the CMT family of book trimmers has evolved adding additional features and models required by today’s short-run book publishers. Designed for the expanding digital print environment, these machines incorporate an industry-exclusive modularity which allows you to tailor your CMT trimmer to provide exactly the level of automation your organization requires. The CMT family of book trimmers provides consistent cut quality, durability, reliability, and the ease-of-use that you have come to expect from Challenge products.'
const DRILL_DESC =
  'From the time when Challenge invented and patented the first paper drill in 1930 until now, we have drawn on over 80 years of experience to offer a superior paper drill for most any need.'
const CORNERING_DESC =
  'The Challenge Single Cornering Machine (SCM) is the perfect solution for production-level round cornering requirements. Available in either hydraulic or manual models, the SCM features a table designed to handle any size stock, offers six optional sizes of precision heavy-duty knife and die sets (one size set included with the machine), integrated chip catch bin, and high pile capacity. Both models are designed to ensure years of trouble-free production.'
const JOGGER_DESC =
  'Challenge paper joggers help save time and make your job easier by quickly squaring-up unorganized stacks of paper. Challenge manufactures a wide range of paper jogging solutions for small offices to large commercial bindery environments.'
const PADDING_DESC =
  'Originally patented by Challenge in 1979, the Paddy Wagon provides a cost-effective solution to padding carbonless forms in an efficient fashion. The Handy Padder offers the same features in a table-top smaller format for low-volume situations.'
const CART_DESC =
  'The PAK-RAK was patented in 1984 and is now the most copied stock cart in existence. Our sturdy steel construction and high storage capacity make the best stock carts in the marketplace! The smaller, more affordable Handy-Cart is another great product value from Challenge, and like all of our products, are engineered to make your job easier.'
const CUTTER_SHORT_DESC =
  'Our current line of paper cutters continue the long legacy of Challenge safety, productivity, innovation, and durability.'
const TRIMMER_SHORT_DESC =
  'Designed for the expanding digital print environment, these machines incorporate an industry-exclusive modularity.'
const DRILL_SHORT_DESC =
  "We've drawn on over 80 years of experience to offer superior paper drilling products for most any need."
const CORNERING_SHORT_DESC =
  'We have the perfect solution for your production-level round cornering requirements.'
const JOGGER_SHORT_DESC =
  'Challenge manufactures a wide range of paper jogging solutions for small offices to large commercial bindery environments.'
const PADDING_SHORT_DESC =
  'Solutions available for both high and low volume padding needs.'
const CART_SHORT_DESC =
  'Safely and easily move stock around with mobile stock carts.'

export const productTypes: ProductTypeId[] = [
  {
    dispName: 'Paper Cutters',
    path: 'paper-cutters',
    apiType: 'paper cutter',
    id: EProduct.CUTTER,
    description: CUTTER_DESC,
    s_description: CUTTER_SHORT_DESC
  },
  {
    dispName: 'Book Trimmers',
    path: 'book-trimmers',
    apiType: 'book trimmer',
    id: EProduct.TRIMMER,
    description: TRIMMER_DESC,
    s_description: TRIMMER_SHORT_DESC
  },
  {
    dispName: 'Paper Drills',
    path: 'paper-drills',
    apiType: 'paper drill',
    id: EProduct.DRILL,
    description: DRILL_DESC,
    s_description: DRILL_SHORT_DESC
  },
  {
    dispName: 'Cornering Machines',
    path: 'cornering-machines',
    apiType: 'cornering machine',
    id: EProduct.CORNER,
    description: CORNERING_DESC,
    s_description: CORNERING_SHORT_DESC
  },
  {
    dispName: 'Paper Joggers',
    path: 'paper-joggers',
    apiType: 'paper jogger',
    id: EProduct.JOGGER,
    description: JOGGER_DESC,
    s_description: JOGGER_SHORT_DESC
  },
  {
    dispName: 'Padding Presses',
    path: 'padding-press',
    apiType: 'padding press',
    id: EProduct.PADDER,
    description: PADDING_DESC,
    s_description: PADDING_SHORT_DESC
  },
  {
    dispName: 'Stock Carts',
    path: 'stock-carts',
    apiType: 'stock cart',
    id: EProduct.CART,
    description: CART_DESC,
    s_description: CART_SHORT_DESC
  }
]

const AppRoutes = (): JSX.Element => (
  <BrowserRouter>
    <App>
      <Routes>
        <Route path="/" element={<Home />} />
        {/*  */}
        <Route
          path={`${URLS.URL_PRODUCTS}/:productType/:productId`}
          element={<ProductDisplay />}
        />
        <Route
          path={`${URLS.URL_PRODUCTS}/:productType`}
          element={<ProductTypes />}
        />
        <Route path={URLS.URL_PRODUCTS} element={<Products />} />
        {/*  */}
        <Route path={URLS.URL_PARTS} element={<Parts />} />
        <Route path={URLS.URL_MACHINEINFO} element={<MachineInfo />} />
        <Route path={URLS.URL_SAFETYINFO} element={<SafetyInfo />} />
        <Route path={URLS.URL_SOFTWARE} element={<SoftwareUpdates />} />
        <Route
          path={`${URLS.URL_SOFTWARE}/:sw_type`}
          element={<SoftwareVersions />}
        />
        <Route path={URLS.URL_WARRANTY} element={<Warranty />} />
        <Route path={URLS.URL_MSDS} element={<Msds />} />
        <Route path={URLS.URL_SUPPORT} element={<Support />} />
        {/*  */}
        <Route path={URLS.URL_DEALERS} element={<Dealers />} />
        {/*  */}
        <Route path={URLS.URL_HISTORY} element={<History />} />
        <Route path={URLS.URL_MISSION} element={<Mission />} />
        <Route path={URLS.URL_ABOUT} element={<About />} />
        {/*  */}
        <Route path={URLS.URL_CONTACT} element={<Contact />} />
        {/*  */}
        <Route path={URLS.URL_PRIVACY} element={<Privacy />} />
        <Route path={URLS.URL_TERMS} element={<Terms />} />
        <Route path={URLS.URL_COOKIES} element={<Cookies />} />
        {/*  */}
        <Route path="*" element={<NoMatchError />} />
      </Routes>
    </App>
  </BrowserRouter>
)

export default AppRoutes
