import React, { useEffect } from 'react'
import '../About.scss'
import '../../../App.scss'
import { Col, Container, Row } from 'react-bootstrap'

const Mission = (): JSX.Element => {
  useEffect(() => {
    console.log('Rendering <Mission />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="About">
      <div className="s1">
        <h1>Challenge's Mission</h1>
        <p>
          Our mission is to design, manufacture, and market products that
          provide our customers the best total value for turning printed pages
          into products.
        </p>
      </div>
      <div className="s2">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7} className="infoCol">
              <h2>To Our Dealers and Customers</h2>
              <p>
                Thank you for investing your valuable time to view our history,
                products, and services via our website. We are extremely proud
                of what we have to offer you.
              </p>
              <p>
                Pride is a word we use a lot at Challenge. More than any other
                word, it expresses our corporate identity. For over 150 years,
                through the great Chicago fire, financial panics, the Great
                Depression, world wars, shifting marketplace demands, and fierce
                competition, Challenge has met every challenge it has faced. The
                driving force behind that long history is the resourcefulness
                and dedication of our employees, which has created a strong
                sense of pride in everything we do. Pride in our customer
                service that conveys an attitude of "we can do that". Pride that
                makes our products perform as promised - longer and with greater
                reliability and productivity. Pride in our technical support
                that is best in our industry. Pride in the way we conduct
                business, with integrity, taking personal responsibility for the
                success of Challenge and in building lasting relationships with
                our dealers and customers. All of this adds up to your best
                total value by investing in Challenge equipment.
              </p>
              <p>
                Although we are proud of our past, we are even more dedicated to
                continually improving and creating value for our customers in
                the future. Our Mission and Statement of Principles, which is
                included below, is a testament of our dedication to this task.
                We hope you see a reflection of that dedication in your normal
                day-to-day relations with us. Thank you for your support.
              </p>
              <h3>Statement of Principles</h3>
              <p>
                We are committed to three main principles that drive our
                company:
              </p>
              <ul>
                <li>
                  Protecting our legacy - for over 150 years, Challenge has
                  worked hard to create superior finishing solutions. Our
                  history of innovation and our commitment to our customers has
                  solidified our name as a staple in the industry.
                </li>
                <li>
                  Continual improvement - of ourselves, our products and our
                  business processes to delight our customers.
                </li>
                <li>
                  Our Employees' well-being - treating employees with dignity
                  and respect, recognizing and rewarding contributions, and
                  maintaining a working environment which is safe and enjoyable.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Mission
