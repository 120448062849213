import React, { useEffect, useState } from 'react'
import '../Support.scss'
import '../../../App.scss'
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Stack,
  Table
} from 'react-bootstrap'
import axios from 'axios'
import componentSwitch from '../../../ComponentSwitch'
import ObjectState from '../../../components/States'
import { useParams } from 'react-router'

const moment = require('moment')

const Software_Versions = (): JSX.Element => {
  const [swListState, setSWListState] = useState<ObjectState>(
    ObjectState.HIDDEN
  )
  const [swList, setSWList] = useState<any>()
  const [downloadEnabled, setDownloadEnabled] = useState<boolean>(true)

  const params = useParams()

  useEffect(() => {
    console.log('Rendering <Software_Updates />')
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getSoftwareVersions({ type: params.sw_type })
  }, [params])

  // // assume there is correct formatting - used to organize sw so version and date are at the top of array
  function date_sort(a: any, b: any) {
    return (
      new Date(b.release_date).getTime() - new Date(a.release_date).getTime()
    )
  }

  const getSoftwareVersions = (query: Object): void => {
    setSWListState(ObjectState.LOADING)
    console.log('performing call to obtain software list..')
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDR}/api/software/json`, {
        params: query ? query : null,
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 10000
      })
      .then(response => {
        console.log('SW list results:', response.data)
        // format software releases by release date, must have release date field
        if (response.data.revisions.length) {
          response.data.revisions.sort(date_sort)
        }
        console.log('UPDATED SW list:', response.data)
        setSWList(response.data)
        setSWListState(ObjectState.SHOWN)
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setSWList({})
        setSWListState(ObjectState.ERROR)
      })
  }

  const getSoftwareFile = (query: any): void => {
    console.log('performing call to obtain software file..')
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDR}/api/software/file`, {
        params: query ? query : null,
        responseType: 'blob',
        timeout: 10000
      })
      .then(response => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', query.file) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
      })
  }

  return (
    <div className="Support">
      {componentSwitch(swListState, switcher =>
        switcher
          .case(ObjectState.HIDDEN, () => null)
          .case(ObjectState.LOADING, () => (
            <div className="s2">
              <p>Loading software information...</p>
              <Spinner animation="border" role="status" />
            </div>
          ))
          .case(ObjectState.SHOWN, () => (
            <>
              <div className="s1">
                <h1>{swList.sw_name}</h1>
                <p>{swList.description}</p>
              </div>
              <div className="s2">
                <h2>Supported Models</h2>
                <p>
                  <b>
                    {swList.supported_models
                      ? swList.supported_models.map(
                          (model: string, key1: number) => {
                            if (key1 + 1 === swList.supported_models.length) {
                              return model
                            } else {
                              return model + ', '
                            }
                          }
                        )
                      : 'name unavailible'}
                  </b>
                </p>
                <p className="css-fix">{swList.special_notes}</p>
                <Container fluid>
                  <Row className="justify-items-center">
                    <Col>
                      <Accordion>
                        {swList.revisions.map((data: any, key: number) => {
                          return (
                            <Accordion.Item key={key} eventKey={key.toString()}>
                              <Accordion.Header>
                                <b>
                                  {data.version
                                    ? data.version
                                    : data.release_date
                                    ? data.release_date
                                    : null}
                                </b>
                                {`: ${data.file_name}`}
                              </Accordion.Header>
                              <Accordion.Body>
                                <Row className="justify-content-center">
                                  <Col className="infoCol">
                                    <p>
                                      <b>Filename:</b> {data.file_name}
                                    </p>
                                    <p>
                                      <b>Release date:</b>{' '}
                                      {moment(
                                        data.release_date,
                                        'YYYY-MM-DD'
                                      ).format('MMMM Do YYYY')}
                                    </p>
                                    {data.version ? (
                                      <p>
                                        <b>Version:</b> {data.version}
                                      </p>
                                    ) : null}
                                    {data.changelog && data.changelog.length ? (
                                      <>
                                        <p>
                                          <b>Change log:</b>
                                        </p>
                                        <ul>
                                          {data.changelog.map(
                                            (
                                              change: string,
                                              key_change: number
                                            ) => {
                                              return (
                                                <li key={key_change}>
                                                  {change}
                                                </li>
                                              )
                                            }
                                          )}
                                        </ul>
                                      </>
                                    ) : null}
                                    <br />
                                    <Stack direction="horizontal" gap={3}>
                                      <Button
                                        disabled={!downloadEnabled}
                                        onClick={() => {
                                          setDownloadEnabled(false)
                                          getSoftwareFile({
                                            type: swList.folder_name,
                                            folder: data.folder_name,
                                            file: data.file_name
                                          })
                                          setTimeout(
                                            () => setDownloadEnabled(true),
                                            5000
                                          )
                                        }}
                                      >
                                        Download
                                      </Button>
                                      <div className="vr" />
                                      {data.file_name}
                                    </Stack>
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          )
                        })}
                      </Accordion>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <h2>Download Instructions</h2>
                    <Col className="infoCol">
                      {swList.instructions.map((step: any, key2: number) => {
                        return (
                          <div key={key2}>
                            <h3>{step.step_title}</h3>
                            <ol key={key2}>
                              {step.sub_steps.map(
                                (sub_step: string, key3: number) => {
                                  return <li key={key3}>{sub_step}</li>
                                }
                              )}
                            </ol>
                          </div>
                        )
                      })}
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
          ))
          .case(ObjectState.ERROR, () => (
            <div className="s2">
              <Row className="align-content-center" style={{ height: '80vh' }}>
                <Col>
                  <p>Unable to load software at this time...</p>
                </Col>
              </Row>
            </div>
          ))
      )}
    </div>
  )
}

export default Software_Versions
