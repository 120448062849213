import React, { useEffect, useState } from 'react'
import '../Support.scss'
import '../../../App.scss'
import { Card, Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import axios from 'axios'
import componentSwitch from '../../../ComponentSwitch'
import ObjectState from '../../../components/States'
import { Link } from 'react-router-dom'

const Software_Updates = (): JSX.Element => {
  const [swListState, setSWListState] = useState<ObjectState>(
    ObjectState.HIDDEN
  )
  const [swList, setSWList] = useState<any[]>([])

  useEffect(() => {
    console.log('Rendering <Software_Updates />')
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getSWList()
  }, [])

  const getSWList = (): void => {
    setSWListState(ObjectState.LOADING)
    console.log('performing call to obtain software list..')
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDR}/api/software`, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 10000
      })
      .then(response => {
        console.log('SW list results:', response.data)
        setSWList(response.data)
        setSWListState(ObjectState.SHOWN)
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setSWList([])
        setSWListState(ObjectState.ERROR)
      })
  }

  return (
    <div className="Support">
      <div className="s1">
        <h1>Software Downloads</h1>
        <p>
          The Challenge Machinery Company provides free downloads for several
          versions of our public software. Download and install the desired
          software for your machine by selecting from the category below. We
          always recommend downloading and installing the latest software
          available for your machine.
        </p>
      </div>
      <div className="s2">
        {componentSwitch(swListState, switcher =>
          switcher
            .case(ObjectState.HIDDEN, () => null)
            .case(ObjectState.LOADING, () => (
              <>
                <p>Loading software information...</p>
                <Spinner animation="border" role="status" />
              </>
            ))
            .case(ObjectState.SHOWN, () => (
              <>
                {swList.map((sw_type, key) => {
                  return (
                    <Card key={key} className="border-2" border="primary">
                      <Link id="card-item" to={`./${sw_type.folder_name}`}>
                        <Card.Body>
                          <Card.Title id="card-title">
                            {sw_type.sw_name
                              ? sw_type.sw_name
                              : 'name unavailible'}
                          </Card.Title>
                          <Card.Text id="card-text">
                            {sw_type.description
                              ? sw_type.description
                              : 'description unavailible'}
                            <br />
                            <br />
                            For Models:
                            <b>
                              {' '}
                              {sw_type.supported_models
                                ? sw_type.supported_models.map(
                                    (model: string, key1: number) => {
                                      if (
                                        key1 + 1 ===
                                        sw_type.supported_models.length
                                      ) {
                                        return model
                                      } else {
                                        return model + ', '
                                      }
                                    }
                                  )
                                : 'name unavailible'}
                            </b>
                            <br />
                          </Card.Text>
                        </Card.Body>
                      </Link>
                    </Card>
                  )
                })}
              </>
            ))
            .case(ObjectState.ERROR, () => (
              <p>Failed to load software information...</p>
            ))
        )}
      </div>
    </div>
  )
}

export default Software_Updates
