import React, { useEffect } from 'react'
import './Info.scss'
import '../../App.scss'
import { Container, Row } from 'react-bootstrap'

const Cookies = (): JSX.Element => {
  useEffect(() => {
    console.log('Rendering <Cookies />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Info">
      <div className="s1">
        <h1>Cookies</h1>
      </div>
      <div className="s2">
        <Container fluid>
          <Row className="justify-content-center">
            <p>
              Our website does not currently use any cookies. This may change in
              the future, so please check back regularly.
            </p>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Cookies
