import React, { useEffect } from 'react'
import './Info.scss'
import '../../App.scss'
import { Container, Row, Col } from 'react-bootstrap'

const Privacy = (): JSX.Element => {
  useEffect(() => {
    console.log('Rendering <Privacy />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Info">
      <div className="s1">
        <h1>Privacy</h1>
      </div>
      <div className="s2">
        <Container fluid>
          <Row className="justify-content-center">
            <Col className="infoCol">
              <h2>Privacy Commitment</h2>
              <p>
                The Challenge Machinery Company fully respects all of our users
                and customers right to privacy. We recognize the importance of
                personal data protection and are committed to respecting the
                information you decide to share with us. In any form your
                information is shared with us, such as through the website or
                phone service, we are committed to protecting your information
                with the upmost care. Your information will not be shared or
                sold to any third parties without your approval.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="infoCol">
              <h2>What information does Challenge collect?</h2>
              <p>
                In order to best serve our customers, we may collect and store
                the following information through phone calls and user forms
                submitted on this site:
              </p>
              <Row style={{ maxWidth: '1000px' }}>
                <Col style={{ minWidth: '300px' }}>
                  <ul>
                    <li>Name</li>
                    <li>Phone number</li>
                    <li>Fax number</li>
                    <li>Address</li>
                    <li>Company name</li>
                    <li>Email</li>
                  </ul>
                </Col>
                <Col style={{ minWidth: '300px' }}>
                  <ul>
                    <li>Dealer's name</li>
                    <li>Your machine's serial number</li>
                    <li>Date of machine purchase</li>
                    <li>Date of machine installation</li>
                    <li>Name of machine installer</li>
                    <li>Your machine's model</li>
                  </ul>
                </Col>
                <Col style={{ minWidth: '300px' }}>
                  <ul>
                    <li>Your website url</li>
                    <li>
                      Questions about machine quality and installation quality
                    </li>
                    <li>Your industry type</li>
                    <li>Size of your business</li>
                    <li>Location</li>
                    <li>Comments about requests</li>
                  </ul>
                </Col>
              </Row>
              <p>
                In addition, The Challenge Machinery Company may store
                information for users and dealers who create accounts on our
                website. Accounts are created by us manually for authorized
                dealers; the information (username and password) created for
                dealer accounts is encrypted and stored on our databases.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="infoCol">
              <h2>What we do with information we collect</h2>
              <p>
                <b>
                  Your information will never be shared with a third party
                  without your approval.
                </b>{' '}
                Challenge may use your information for the following purposes.
              </p>
              <p>
                Your information may be used to help assist you with any sales,
                technical support, or general inquiries you submit on our
                website. Your information may also be recorded for the same
                reasons during a phone call to our support lines. Challenge will
                never send unsolicited mail or emails unless you explicitly send
                a request for contact.
              </p>
              <p>
                Your information may be used to help us better understand our
                product marketing and promotional efforts. The information
                shared may help us improve our product development and enhance
                our site based on user trends and feedback.
              </p>
              <p>
                Personal information shared on our website is sent to the
                appropriate internal department at The Challenge Machinery
                Company. This information is not released outside of the company
                unless you grant permission. This information is used to assist
                the user when they attempt to contact us with a request or
                problem.
              </p>
              <p>
                The only data shared by the users that we stored in our
                databases is the Warranty information. Like all information
                collected, it is only used internally to help assist the
                corresponding customer. It is never shared between clients or
                third parties.
              </p>
              <p>
                Usernames and password generated by us for dealer logins are
                encrypted and stored on our database. Please contact our support
                line if you wish to register as a dealer and to obtain your
                login credentials.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="infoCol">
              <h2>Policy Changes</h2>
              <p>
                The practices and procedures described in this policy are
                subject to change. All changes to this policy will be
                communicated on our website. All information collected under
                previous policies will remain subject to the policies
                procedures.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="infoCol">
              <h2>Your Consent</h2>
              <p>
                By using The Challenge Machinery Website or calling us to
                provide your information, you consent to the collection and use
                of your information in the manner described.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Privacy
