// This file defines all of the routing pages for the website

// Home
export const URL_HOME = '/'
// Login
export const URL_LOGIN = 'login'
export const URL_DASHBOARD = 'dashboard'
// Products
export const URL_PRODUCTS = 'products'
// Support
export const URL_SUPPORT = 'support'
export const URL_PARTS = `${URL_SUPPORT}/parts`
export const URL_MACHINEINFO = `${URL_SUPPORT}/machine-info`
export const URL_SAFETYINFO = `${URL_SUPPORT}/safety-info`
export const URL_SOFTWARE = `${URL_SUPPORT}/sw-updates`
export const URL_WARRANTY = `${URL_SUPPORT}/warranty`
export const URL_MSDS = `${URL_SUPPORT}/msds`
// Dealers
export const URL_DEALERS = 'dealers'
// About
export const URL_ABOUT = 'about'
export const URL_HISTORY = `${URL_ABOUT}/history`
export const URL_MISSION = `${URL_ABOUT}/mission`
// Contact
export const URL_CONTACT = 'contact'
// Footers
export const URL_PRIVACY = 'privacy'
export const URL_TERMS = 'terms'
export const URL_COOKIES = 'cookies'
