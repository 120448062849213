import React, { useEffect, useState } from 'react'
import './Dealers.scss'
import '../../App.scss'
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Stack
} from 'react-bootstrap'
import componentSwitch from '../../ComponentSwitch'
import ObjectState from '../../components/States'
import { MyMap } from './GoogleMap'
import axios from 'axios'

type FormItems = {
  region: string
  field: string
}

const Dealers = (): JSX.Element => {
  const RegionUSA = 'United States'
  const RegionCanada = 'Canada'
  const RegionGlobal = 'Global'

  const defaultProvince = '-Select Province-'
  const defaultCountry = '-Select Country-'

  const [region, setRegion] = useState<string>(RegionUSA)

  const [optionState, setOptionState] = useState<ObjectState>(
    ObjectState.HIDDEN
  )
  const [zip, setZip] = useState<string>('')
  const [province, setProvince] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [provinces, setProvinces] = useState<Object[]>([])
  const [countries, setCountries] = useState<Object[]>([])

  const [dealerState, setDealersState] = useState<ObjectState>(
    ObjectState.HIDDEN
  )
  const [dealers, setDealers] = useState<any[]>([])
  const [autoLocStatus, setAutoLocStatus] = useState<ObjectState>(
    ObjectState.HIDDEN
  )
  const [autoLoc, setAutoLoc] = useState<FormItems>({ region: '', field: '' })
  const [origin, setOrigin] = useState<GeolocationPosition>()

  useEffect(() => {
    console.log('Rendering <Dealers />')
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    console.log('autoLoc status updated:', autoLocStatus)
  }, [autoLocStatus])

  useEffect(() => {
    console.log('autoLoc updated:', autoLoc)
    if (autoLoc.field !== '' && autoLoc.field !== 'error') {
      updateRegion(autoLoc.region, true)
      if (autoLoc.region === 'United States') {
        setZip(autoLoc.field)
        getDealers({ zip: autoLoc.field.substring(0, 3) })
      }
    }
  }, [autoLoc])

  useEffect(() => {
    console.log('province or province list changed:', province, provinces)
    if (province === defaultProvince || province === '') {
      setDealers([])
    } else if (provinces.length > 1) {
      getDealers({ province: province })
    }
  }, [province, provinces])

  useEffect(() => {
    console.log('country changed:', country, countries)
    if (country === defaultCountry || country === '') {
      setDealers([])
    } else if (countries.length > 1) {
      getDealers({ country: country })
    }
  }, [country, countries])

  useEffect(() => {
    console.log('dealers updated:', dealers)
    if (dealers.length <= 0) {
      setDealersState(ObjectState.HIDDEN)
    }
  }, [dealers])

  const updateRegion = (_region: string, auto: boolean): void => {
    console.log('region updated:', _region)
    setRegion(_region)
    setDealersState(ObjectState.HIDDEN)
    if (!auto) {
      setAutoLocStatus(ObjectState.HIDDEN)
      setOrigin(undefined)
    }
    if (_region === RegionUSA) setOptionState(ObjectState.SHOWN)
    else if (_region === RegionCanada) {
      getProvinces(auto)
    } else if (_region === RegionGlobal) {
      getCountries(auto)
    }
  }

  function getposition() {
    //If granted then you can directly call your function here
    navigator.geolocation.getCurrentPosition(position => {
      console.log(position)
      setOrigin(position)
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`
      )
        // --- test fetch ---
        // fetch(
        //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=-77,4&key=${process.env.REACT_APP_MAPS_API_KEY}`
        // )
        .then(res => res.json())
        .then(res => {
          console.log(res)
          let out: FormItems = { region: '', field: '' }
          // determine if there is a valid result
          try {
            if (res.status === 'OK' && res.results.length > 0) {
              for (let entry of res.results) {
                for (let type of entry.types) {
                  // parse the response to determine country
                  if (type === 'country') {
                    switch (entry.formatted_address) {
                      case 'Canada':
                      case 'United States':
                        out.region = entry.formatted_address
                        break
                      default:
                        out.region = 'Global'
                        out.field = entry.formatted_address
                        break
                    }
                  }
                }
              }
              if (out.region === 'United States') {
                for (let entry of res.results) {
                  for (let type of entry.types) {
                    if (type === 'postal_code') {
                      for (let component of entry.address_components) {
                        for (let type2 of component.types) {
                          if (type2 === 'postal_code') {
                            out.field = component.short_name
                            break
                          }
                        }
                      }
                    }
                  }
                }
              } else if (out.region === 'Canada') {
                for (let entry of res.results) {
                  for (let type of entry.types) {
                    if (type === 'postal_code') {
                      for (let component of entry.address_components) {
                        for (let type2 of component.types) {
                          if (type2 === 'administrative_area_level_1') {
                            out.field = component.long_name
                            break
                          }
                        }
                      }
                    }
                  }
                }
              }
              if (out.region === '' && out.field === '') {
                throw Error('region or field not found')
              } else {
                console.log(out)
              }
            } else {
              console.log('No results for user location')
            }
          } catch (exception) {
            out = { region: '', field: '' }
            console.log('unable to determine location', exception)
          }
          setAutoLoc(out)
          setAutoLocStatus(ObjectState.SHOWN)
        })
        .catch(err => {
          console.log(err)
          setAutoLocStatus(ObjectState.ERROR)
        })
    })
  }

  function getLocation() {
    if (navigator.geolocation) {
      console.log('getting geolocation...')
      setAutoLocStatus(ObjectState.LOADING)
      if (navigator.permissions) {
        // platform supports navigator permissions
        navigator.permissions
          .query({ name: 'geolocation' })
          .then(function (result) {
            console.log('geolocation results:', result.state)
            if (result.state === 'granted' || result.state === 'prompt') {
              getposition()
            } else if (result.state === 'denied') {
              //If denied then you have to show instructions to enable location
              alert(
                'Location access denied. \nPlease enable location services to use this feature.'
              )
            }
            result.onchange = function () {
              console.log(result.state)
            }
          })
      } else {
        // platform does not support navigator permissions
        getposition()
      }
    } else {
      alert('Geolocation not available on this device!')
    }
  }

  const getCountries = (auto: boolean) => {
    setOptionState(ObjectState.LOADING)
    console.log('performing api call to get countries...')
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDR}/api/dealers/global`, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 10000
      })
      .then(response => {
        if (response.data[0].error) {
          console.log('api call failed...')
          setProvinces([])
          setOptionState(ObjectState.ERROR)
        } else {
          setCountries(response.data)
          setOptionState(ObjectState.SHOWN)
          if (auto) {
            console.log(
              'entering if statement because auto location is triggered',
              autoLocStatus,
              autoLoc
            )
            let found = false
            for (let country of response.data) {
              if (country.countries === autoLoc.field) {
                found = true
                setCountry(country.countries)
                break
              }
            }
            if (!found) {
              console.log('error... no matching countries')
              setAutoLocStatus(ObjectState.ERROR)
            }
          }
        }
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setCountries([])
        setOptionState(ObjectState.SHOWN)
      })
  }

  const getProvinces = (auto: boolean) => {
    setOptionState(ObjectState.LOADING)
    console.log('performing api call to get provinces...')
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDR}/api/dealers/canada`, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 10000
      })
      .then(response => {
        if (response.data[0].error) {
          console.log('api call failed...')
          setProvinces([])
          setOptionState(ObjectState.ERROR)
        } else {
          setProvinces(response.data)
          setOptionState(ObjectState.SHOWN)
          if (auto) {
            console.log(
              'entering if statement because auto location is triggered',
              autoLocStatus,
              autoLoc
            )
            let found = false
            for (let prov of response.data) {
              if (prov.provinces === autoLoc.field) {
                found = true
                setProvince(prov.provinces)
                break
              }
            }
            if (!found) {
              console.log('error... no matching province')
              setAutoLocStatus(ObjectState.ERROR)
            }
          }
        }
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setProvinces([])
        setOptionState(ObjectState.SHOWN)
      })
  }

  const getDealers = (query: Object) => {
    setDealersState(ObjectState.LOADING)
    console.log('performing dealers api call with queries:', query)
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDR}/api/dealers`, {
        params: query !== undefined ? query : {},
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 10000
      })
      .then(response => {
        console.log('dealer results:', response.data)
        if (response.data[0].error) {
          console.log('api call failed...')
          setDealers([])
          setDealersState(ObjectState.ERROR)
        } else {
          setDealers(response.data)
          setDealersState(ObjectState.SHOWN)
        }
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setDealers([])
        setDealersState(ObjectState.ERROR)
      })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.stopPropagation()
    }
    getDealers({ zip: zip.substring(0, 3) })
  }

  return (
    <div className="Dealers">
      <div className="s2">
        <Container>
          <Row className="justify-content-center">
            <Col sm md={5} lg={6} xl={7} xxl={8}>
              <h1>Dealer Finder</h1>
              <p>
                Find a dealer close to you by using The Challenge Machinery
                Dealer Finder. Automatically find dealers near you by clicking
                the button below. Alternatively, fill out the information to the
                right to generate a list of authorized dealers that can help
                with all your product needs!
              </p>
              <Row className="justify-content-center">
                <Col md="auto">
                  <Button onClick={getLocation}>Use My Location</Button>
                </Col>
              </Row>
              {componentSwitch(autoLocStatus, switcher =>
                switcher
                  .case(ObjectState.HIDDEN, () => null)
                  .case(ObjectState.LOADING, () => null)
                  .case(ObjectState.SHOWN, () => (
                    <>
                      <br />
                      <Row>
                        <p>Showing results based on your location</p>
                      </Row>
                    </>
                  ))
                  .case(ObjectState.ERROR, () => (
                    <>
                      <br />
                      <Row>
                        <p>No dealers could be found near your location...</p>
                      </Row>
                    </>
                  ))
              )}
            </Col>
            <Col>
              <Form onSubmit={e => onSubmit(e)}>
                <Row className="align-items-center">
                  <Form.Label column sm={3}>
                    Region
                  </Form.Label>
                  <Col>
                    <Form.Select
                      aria-label={'Region'}
                      value={region}
                      onChange={e => {
                        console.log('Region selected as:', e.target.value)
                        setAutoLoc({ region: '', field: '' })
                        setAutoLocStatus(ObjectState.HIDDEN)
                        updateRegion(e.target.value, false)
                      }}
                    >
                      <option>{RegionUSA}</option>
                      <option>{RegionCanada}</option>
                      <option>{RegionGlobal}</option>
                    </Form.Select>
                  </Col>
                </Row>
                <hr />
                {componentSwitch(region, switcher =>
                  switcher
                    .case(RegionUSA, () => (
                      <Row className="align-items-center">
                        <Form.Label column sm={3}>
                          Zip Code
                        </Form.Label>
                        <Col>
                          <Form.Control
                            required
                            type="text"
                            onChange={e => {
                              setZip(e.target.value)
                            }}
                            pattern=".{3,5}"
                            value={zip}
                            placeholder="ex: 49441"
                          />
                        </Col>
                        <Col>
                          <Button
                            type="submit"
                            disabled={dealerState === ObjectState.LOADING}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    ))
                    .case(RegionCanada, () => (
                      <Row className="align-items-center">
                        <Form.Label column sm={3}>
                          Province
                        </Form.Label>
                        <Col>
                          {optionState === ObjectState.LOADING ? (
                            <Spinner animation="border" role="status" />
                          ) : (
                            <Form.Select
                              aria-label="Province"
                              value={province}
                              onChange={e => {
                                console.log(
                                  'province selected:',
                                  e.target.value
                                )
                                setAutoLoc({ region: '', field: '' })
                                setAutoLocStatus(ObjectState.HIDDEN)
                                setProvince(e.target.value)
                              }}
                            >
                              <option>{defaultProvince}</option>
                              {provinces &&
                                provinces.map((obj: Object) => {
                                  return Object.values(obj).map((val, k) => {
                                    return <option key={k}>{val}</option>
                                  })
                                })}
                            </Form.Select>
                          )}
                        </Col>
                      </Row>
                    ))
                    .case(RegionGlobal, () => (
                      <Row className="align-items-center">
                        <Form.Label column sm={3}>
                          Country
                        </Form.Label>
                        <Col>
                          {optionState === ObjectState.LOADING ? (
                            <Spinner animation="border" role="status" />
                          ) : (
                            <Form.Select
                              value={country}
                              aria-label="Country"
                              onChange={e => {
                                console.log('country selected:', e.target.value)
                                setAutoLoc({ region: '', field: '' })
                                setAutoLocStatus(ObjectState.HIDDEN)
                                setCountry(e.target.value)
                              }}
                            >
                              <option>{defaultCountry}</option>
                              {countries &&
                                countries.map((obj: Object) => {
                                  return Object.values(obj).map((val, k) => {
                                    return <option key={k}>{val}</option>
                                  })
                                })}
                            </Form.Select>
                          )}
                        </Col>
                      </Row>
                    ))
                )}
              </Form>
            </Col>
          </Row>
          <br />
          <hr />
          <br />
          {componentSwitch(dealerState, switcher =>
            switcher
              .case(ObjectState.HIDDEN, () => null)
              .case(ObjectState.LOADING, () => (
                <Spinner animation="border" role="status" />
              ))
              .case(ObjectState.SHOWN, () => (
                <Row>
                  <Col xl={6}>
                    <MyMap dealers={dealers} userLocation={origin} />
                  </Col>
                  <Col>
                    {dealers &&
                      dealers.map((data: any, i) => {
                        return (
                          <div key={i}>
                            <Row className="align-items-center">
                              <Col>
                                <Stack gap={0} id="dealerBlock">
                                  {data.Logo ? (
                                    <a
                                      key={i}
                                      href={`http://${data.Website}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        className="DealerLogo"
                                        key={i}
                                        alt={data.Logo}
                                        src={`/dealer_logos/${data.Logo}`}
                                      />
                                    </a>
                                  ) : null}
                                  <h4>{data.Company}</h4>
                                  {data.Address1 ? (
                                    <p>{data.Address1}</p>
                                  ) : null}
                                  {data.Address2 ? (
                                    <p>{data.Address2}</p>
                                  ) : null}
                                  {data.Address3 ? (
                                    <p>{data.Address3}</p>
                                  ) : null}
                                  {data.Address4 ? (
                                    <p>{data.Address4}</p>
                                  ) : null}
                                </Stack>
                              </Col>
                              <Col>
                                {data.Rank !== undefined &&
                                parseInt(data.Rank) <= 10 ? (
                                  <>
                                    {console.log('dealer rank:', data.Rank)}
                                    <img
                                      alt="Top Ten Dealer"
                                      src="/dealer_logos/top_ten_dealer.jpg"
                                      width="auto"
                                      height="auto"
                                    />
                                  </>
                                ) : null}
                                <Stack gap={0} id="dealerBlock">
                                  {data.Website ? (
                                    <p>
                                      <a
                                        key={i}
                                        href={`http://${data.Website}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >{`${data.Website}`}</a>
                                    </p>
                                  ) : null}
                                  {data.Email ? (
                                    <p>Email: {data.Email}</p>
                                  ) : null}
                                  {data.Phone ? (
                                    <p>Phone: {data.Phone}</p>
                                  ) : null}
                                  {data.Fax ? <p>Fax: {data.Fax}</p> : null}
                                </Stack>
                              </Col>
                            </Row>
                            <hr />
                          </div>
                        )
                      })}
                  </Col>
                </Row>
              ))
              .case(ObjectState.ERROR, () => <p>Unable to retrieve data...</p>)
          )}
        </Container>
      </div>
    </div>
  )
}

export default Dealers
