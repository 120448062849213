enum EProduct {
  UNKNOWN = 0,
  CUTTER = 1,
  TRIMMER,
  CORNER,
  DRILL,
  JOGGER,
  PADDER,
  CART
}

export default EProduct
