import React, { useEffect } from 'react'
import './About.scss'
import '../../App.scss'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as URLS from '../../components/RouteList'

const About = (): JSX.Element => {
  useEffect(() => {
    console.log('Rendering <About />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="About">
      <div className="s1">
        <h1>About Challenge</h1>
        <p>
          Learn about Challenge's rich history, or check out some of the company
          reviews. Click one of the links below to find out more.
        </p>
      </div>
      <hr />
      <div className="s2">
        <h1>History</h1>
        <p>
          The Challenge Machinery Company has a long lineage of excellence in
          machine quality and innovation. We are proud of our history and the
          company standards that have kept us in business for over 150 years.
        </p>
        <Link to={`/${URLS.URL_HISTORY}`}>
          <Button variant="primary" size="lg">
            View Page
          </Button>
        </Link>
      </div>
      <div className="s3">
        <h1>Mission</h1>
        <p>
          Check out or mission statement and the legacy of Challenge Machinery
        </p>
        <Link to={`/${URLS.URL_MISSION}`}>
          <Button variant="light" size="lg">
            View Page
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default About
