import React, { FC } from 'react'
import { Link } from 'react-router-dom'
//css includes
import './App.scss'

// bootstrap includes
import { Nav, NavDropdown } from 'react-bootstrap'
import { ProductTypeId, productTypes } from './AppRoutes'
import * as URLS from './components/RouteList'

interface Props {
  classMod: string
  dropDir?: string
  isExpanded: boolean
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>
}

const NavMenu: FC<Props> = ({
  classMod = '',
  isExpanded = false,
  setExpanded
}) => {
  const clickHandler = () => {
    return isExpanded && setExpanded !== undefined
      ? setExpanded(false)
      : undefined
  }
  return (
    <Nav className={classMod}>
      <Nav.Link as={Link} to={URLS.URL_HOME} onClick={clickHandler}>
        Home
      </Nav.Link>
      <NavDropdown title="Products">
        <div id="drop-nav">
          <NavDropdown.Item
            as={Link}
            to={URLS.URL_PRODUCTS}
            onClick={clickHandler}
          >
            All Products
          </NavDropdown.Item>
          {productTypes.map((element: ProductTypeId, key) => (
            <NavDropdown.Item
              key={key}
              as={Link}
              to={`${URLS.URL_PRODUCTS}/${element.path}`}
              onClick={clickHandler}
            >
              {element.dispName}
            </NavDropdown.Item>
          ))}
        </div>
      </NavDropdown>
      <NavDropdown title="Support">
        <div id="drop-nav">
          <NavDropdown.Item
            as={Link}
            to={URLS.URL_MACHINEINFO}
            onClick={clickHandler}
          >
            Manuals, Year Built, Support Status
          </NavDropdown.Item>
          <NavDropdown.Item
            as={Link}
            to={URLS.URL_SOFTWARE}
            onClick={clickHandler}
          >
            Software Downloads
          </NavDropdown.Item>
          <NavDropdown.Item
            as={Link}
            to={URLS.URL_PARTS}
            onClick={clickHandler}
          >
            Replacement Parts
          </NavDropdown.Item>
          <NavDropdown.Item
            as={Link}
            to={URLS.URL_WARRANTY}
            onClick={clickHandler}
          >
            Submit Warranty
          </NavDropdown.Item>
          <NavDropdown.Item
            as={Link}
            to={URLS.URL_SAFETYINFO}
            onClick={clickHandler}
          >
            Product Safety
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to={URLS.URL_MSDS} onClick={clickHandler}>
            MSDS Downloads
          </NavDropdown.Item>
        </div>
      </NavDropdown>
      <Nav.Link as={Link} to={URLS.URL_DEALERS} onClick={clickHandler}>
        Dealer Finder
      </Nav.Link>
      <NavDropdown title="About">
        <div id="drop-nav">
          <NavDropdown.Item
            as={Link}
            to={URLS.URL_HISTORY}
            onClick={clickHandler}
          >
            History
          </NavDropdown.Item>
          <NavDropdown.Item
            as={Link}
            to={URLS.URL_MISSION}
            onClick={clickHandler}
          >
            Mission
          </NavDropdown.Item>
        </div>
      </NavDropdown>
      <Nav.Link as={Link} to={URLS.URL_CONTACT} onClick={clickHandler}>
        Contact
      </Nav.Link>
    </Nav>
  )
}

export default NavMenu
