import { FC, ReactNode, useEffect } from 'react'
import './App.scss'

import Navigation from './Navigation'
import Footer from './Footer'

type Props = {
  children?: ReactNode
}

const App: FC<Props> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    // todds commit
  }, [])
  return (
    <div className="flex-wrapper">
      <div className="header">
        <Navigation />
      </div>
      <div className="content">
        {/* Typically the children here are defined by the router links */}
        {children}
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}

export default App
