import React from 'react'

// Dependencies
import ReactDOM from 'react-dom'

// Components
import AppRoutes from './AppRoutes'

// Config
// import config from './config'

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>,
  document.getElementById('root')
)
