import React, { useEffect } from 'react'
import '../Support.scss'
import '../../../App.scss'
import { Accordion, Col, Container, Row, Table } from 'react-bootstrap'

const msds = [
  {
    name: '4688 Drill Ease Stick Lubricant',
    link: '4688_Drill_Ease_Stick_Lubricant.pdf'
  },
  { name: '6614 Drill Head Grease', link: '6614_Drill_Head_Grease.pdf' },
  {
    name: '6629 Gib Slide Lube SPO255',
    link: '6629_Gib_Slide_Lube_SPO255.pdf'
  },
  {
    name: 'S-1991-3 ISO 46 Hydraulic Oil',
    link: 'S-1991-3_ISO_46_Hydraulic Oil.pdf'
  },
  {
    name: 'S-1991-4 ISO 100 Hydraulic Oil',
    link: 'S-1991-4_ISO_100_Hydraulic_Oil.pdf'
  },
  {
    name: 'SU-30-103 MOLY-GRAPH Multi-Purpose Grease',
    link: 'SU-30-103_MOLY-GRAPH_Multi-Purpose_Grease.pdf'
  },
  {
    name: 'SU-30-104 Red General Machine Grease',
    link: 'SU-30-104_Red_General_Machine_Grease.pdf'
  },
  {
    name: 'SU-30-105 New Spartan Gear Box Lube',
    link: 'SU-30-105_New_Spartan_Gear_Box_Lube.pdf'
  },
  {
    name: 'Rust-B-Gone',
    link: 'Rust-B-Gone.pdf'
  },
  {
    name: 'Silicone Release No. 30',
    link: 'Silicone_Release_No30.pdf'
  },
  {
    name: 'Table Wax',
    link: 'Table_Wax.pdf'
  }
]

const Msds = (): JSX.Element => {
  useEffect(() => {
    console.log('Rendering <Msds />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Support">
      <div className="s1">
        <h1>MSDS Downloads</h1>
        <p>
          The Challenge Machinery Company provides Material Saftey Data Sheets
          (MSDS) for all of our production lubricants, waxes, maintinance
          supplies, and hydraulic oils. Please refer to the list below for the
          MSDS of your Challenge Machinery product.
        </p>
      </div>
      <div className="s2">
        <Container>
          <Row className="justify-content-center">
            <Col md="auto" lg={7}>
              <div className="fixedTable">
                <Table bordered hover striped>
                  <thead>
                    <tr>
                      <th>
                        <h3>MSDS Download Links</h3>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {msds.map((m, key) => (
                      <tr key={key}>
                        <td>
                          <a
                            href={`/msds/${m.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {m.name}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Msds
