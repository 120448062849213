import React, { useEffect } from 'react'
import '../Support.scss'
import '../../../App.scss'
import { Accordion, Col, Container, Row, Table } from 'react-bootstrap'

const Safety_Info = (): JSX.Element => {
  useEffect(() => {
    console.log('Rendering <Safety_Info />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Support">
      <div className="s1">
        <h1>Safety Information</h1>
        <p>
          Safety is a top priority at The Challenge Machinery Company. We work
          hard to ensure all of our machines meet industry safety standards and
          are equipped with the latest safety technology. If any indication of
          hazards arise, we always make public our suggestions for appropriately
          handling the situation to maintain a safe working environment.
        </p>
      </div>
      <div className="s2">
        <Container fluid>
          <Row className="justify-content-center">
            <Col>
              <h1>Safety Bulletins</h1>
              <div className="fixedTable">
                <Table striped bordered hover style={{ marginBottom: 0 }}>
                  <thead>
                    <tr>
                      <th key={1}>Machine Model</th>
                      <th key={2}>Announcement Date</th>
                      <th key={3}>Detail Documentation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          Diamond 193, 265, 305 Champion 305 MC, MCPB, MPX, MPC,
                          CRT -
                          <br />
                          Potential malfunction of the 4-way directional control
                          valve
                        </p>
                      </td>
                      <td>
                        <p>March 20, 2017</p>
                      </td>
                      <td>
                        <a
                          href={
                            '/safety_bulletins/PSB305002_Champion-Diamond_valves.pdf'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          PSB305002_Champion-Diamond_valves.pdf
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Champion 370 X -
                          <br />
                          Clevis Inspection
                        </p>
                      </td>
                      <td>
                        <p>November 11, 2014</p>
                      </td>
                      <td>
                        <a
                          href={
                            '/safety_bulletins/PSB370001_Champion370_clevis_inspection.pdf'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          PSB370001_Champion370_clevis_inspection.pdf
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Diamond 193, 265, 305 Champion 305 MC, MCPB, MPX, MPC,
                          CRT -
                          <br />
                          Potential malfunction of the 4-way directional control
                          valve
                        </p>
                      </td>
                      <td>
                        <p>November 1, 2014</p>
                      </td>
                      <td>
                        <a
                          href={
                            '/safety_bulletins/PSB305001_Champion-Diamond_valves_Rev_A.pdf'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          PSB305001_Champion-Diamond_valves_Rev_A.pdf
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="s3">
        <h1>Operating and Safety Tips</h1>
        <p>
          Challenge provides guides for users and technicians on how to properly
          and safely operate their CMC equipment. Find more information on how
          to get the most out of your machine with saftey in mind by referencing
          the documents below.
        </p>
      </div>
      <div className="s2">
        <Container fluid>
          <Row className="align-items-center">
            <Col>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Cutter Safety and You</Accordion.Header>
                  <Accordion.Body>
                    This pamphlet describes the key safety measure when working
                    with a paper cutter -{'  '}
                    <a
                      href={'/safety_bulletins/Cutter_Safety_and_You.pdf'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click to view
                    </a>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Cutter Safety Video</Accordion.Header>
                  <Accordion.Body>
                    This video demonstrates the key techniques and saftey
                    procedures when operating a paper cutter -{'  '}
                    <a
                      href={'https://youtu.be/I_-0X5GIC_k'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click to view
                    </a>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Paper Cutting Tips</Accordion.Header>
                  <Accordion.Body>
                    <Row className="justify-content-center">
                      <Col className="infoCol">
                        <ul>
                          <li>
                            Carefully lay out each sheet before you start
                            cutting. Find the best cut pattern to give you the
                            most pieces out of the sheet. If the sheet will be
                            folded, be sure grain of the paper is running in the
                            same direction as the fold or you will get a rough
                            edge on the fold.
                          </li>
                          <li>
                            If an accurate cut is necessary for close register
                            work, you MUST have a sharp blade in the cutter. A
                            dull blade will pull or draw the paper and cause
                            uneven cutting. Increased clamp pressure will not
                            eliminate draw caused by a dull knife.
                          </li>
                          <li>
                            The correct clamping pressure varies from paper to
                            paper. The general rule is that you should have
                            enough pressure to hold the paper securely but not
                            so much that it marks the surface of the paper
                            excessively. Excessive pressure causes pile
                            distortion and inaccurate cuts.
                          </li>
                          <li>
                            Mark the gripper edge and the guide edge of printed
                            paper and make sure the first cuts are with these
                            guide edges against the backgauge.
                          </li>
                          <li>
                            Measure printed paper to check for shrinkage or
                            expansion of the paper from humidity. You may have
                            to disregard the printed cut lines and make your
                            own.
                          </li>
                          <li>
                            When cutting narrow strips (1” or less), place lifts
                            of equal height on opposite sides of the table to
                            prevent wear of the clamp guides.
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Paper Cutter Table Maintenance
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="justify-content-center">
                      <Col className="infoCol">
                        <p>
                          The table of a paper cutter requires periodic
                          maintenance to remove surface oxidation. Polishing is
                          also required to provide a smooth surface for paper to
                          move freely. The frequency of this maintenance will be
                          determined by a number of factors. Among these are the
                          humidity, environmental dust, handprints, liquid
                          spills, and type of paper stock. We recommend the use
                          of the Challenge Cutter Care Kit P/N 16077 for of your
                          table care needs.
                        </p>
                        <p>
                          To prepare a new machine’s table, follow the procedure
                          below:
                        </p>
                        <ol>
                          <li>
                            Remove the rust-protective coating from the table
                            with a solvent.
                          </li>
                          <li>
                            Remove all solvent residue from the table with a dry
                            cloth. Continue until the cloth your are using shows
                            no sign of residue.
                          </li>
                          <li>
                            Apply a light coating of an SAE 10-weight
                            non-detergent motor oil or equivalent to the table
                            and allow it to penetrate for at least one
                            hour.&nbsp;
                          </li>
                          <li>
                            Remove all excess oil from the table with paper
                            toweling (not cloth) until the paper towel you are
                            using shows no sign of oil.
                          </li>
                          <li>
                            Apply a paste wax (Challenge P/N 16078) to the table
                            to seal the pores of the metal.&nbsp;
                          </li>
                        </ol>
                        <p>
                          Note: Do not use a wax that contains a cleaning
                          compound on the table. The cleaner contains
                          microscopic abrasive particles that will cause wear
                          between the table and the bottom of the backgauge. A
                          silicone spray (Challenge P/N 16079) will show the
                          same type of wear as the cleaner if the excess
                          silicone is not removed. If the excess is not removed,
                          the silicone spray has a substance that holds the
                          silicone to the surface it is sprayed on that causes a
                          black, gummy build-up under the backgauge. If a
                          silicone spray is used, paper toweling must be used to
                          remove the excess to prevent this wear and build-up.
                        </p>
                        <p>
                          To clean surface oxidation from a table, follow the
                          procedure below:
                        </p>
                        <ol>
                          <li>
                            Spray “Rust-B-Gone” (Challenge P/N 16080) on the
                            table and allow it to dissolve the rust. Then remove
                            it with paper toweling. Or, pour a small quantity of
                            an SAE 10-weight motor oil onto the table. Using a
                            Scotch-Brite Pad or a 400 grit sand paper, polish
                            the table following the “grain” of the metal until
                            all oxidation is removed to your satisfaction.&nbsp;{' '}
                          </li>
                          <li>
                            Remove all of the oil from the table until the cloth
                            you are using shows no sign of residue.
                          </li>
                          <li>
                            Apply a light coating of an SAE 10-weight
                            non-detergent motor oil or equivalent to the table
                            and allow it to penetrate for at least one
                            hour.&nbsp;{' '}
                          </li>
                          <li>
                            Remove all excess oil from the table with paper
                            toweling (not cloth) until the paper towel you are
                            using shows no sign of oil.
                          </li>
                          <li>
                            Apply a paste wax (Challenge P/N 16078) to the table
                            to seal the pores of the metal.&nbsp;
                          </li>
                        </ol>
                        <p>
                          Note: Do not use a wax that contains a cleaning
                          compound on the table. The cleaner contains
                          microscopic abrasive particles that will cause wear
                          between the table and the bottom of the backgauge. A
                          silicone spray (Challenge P/N 16079) will show the
                          same type of wear as the cleaner if the excess
                          silicone is not removed. If the excess is not removed,
                          the silicone spray has a substance that holds the
                          silicone to the surface it is sprayed on that causes a
                          black, gummy build-up under the backgauge. If a
                          silicone spray is used, paper toweling must be used to
                          remove the excess to prevent this wear and build-up.
                        </p>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Knife Care Tips</Accordion.Header>
                  <Accordion.Body>
                    <Row className="justify-content-center">
                      <Col className="infoCol">
                        <p>
                          <b>KNIFE SAFETY ! Knives are DANGEROUS!!!</b> They are
                          heavy and very sharp, even after use. Keep the edge
                          away from your body and keep the area clear of others
                          when handling knives. Never touch the cutting edge! To
                          prevent personal injury and damage to the knife,
                          always keep knives in their holders with screws
                          tightened. You are aware of the dangers, but others
                          may not be. Never attempt to hone, polish, or service
                          the knife in any way. Failure to follow safety
                          procedures may result in severe lacerations or
                          dismemberment.
                        </p>
                        <p>
                          <b>Knife Blade Life</b>
                        </p>
                        <p>
                          Knife blade life, or the time between sharpenings, can
                          be affected by many factors. One important factor is
                          the type of paper being cut. Abrasive paper, such as
                          recycled paper, soft paper such as newsprint paper,
                          and bound books can all significantly shorten knife
                          blade life. Also, if the knife depth is set too deep,
                          the knife will cut too deep into the cutting stick and
                          can dull the knife blade.
                        </p>
                        <p>
                          A knife can last anywhere between 2,000 and 5,000 cuts
                          before it needs to be sharpened. Cutting soft paper
                          (such as newsprint paper) or paper with high
                          post-consumer recycled content can cause the knife to
                          need sharpening after only 2,000 to 3,000 cuts.
                          Cutting pure paper, such as bond paper with no
                          recycled content, or hard paper can allow the knife to
                          be used for as many as 5,000 cuts before it needs to
                          be sharpened. In all cases, the operator should
                          continually check the quality of the cut to determine
                          when the knife blade needs to be sharpened. Some
                          characteristics that indicate a blade needs sharpening
                          are:
                        </p>
                        <ul>
                          <li>
                            The knife hesitates or stalls while making a cut.
                          </li>
                          <li>
                            The sheets are not all cut to the same length
                            (usually the top few sheets are longer than the rest
                            of the sheets - this is sometimes called “draw”).
                          </li>
                          <li>
                            Cut marks appear on the cut face of the paper.
                          </li>
                          <li>
                            The profile of the cut (side view) is not
                            perpendicular to the table.
                          </li>
                          <li>
                            The cut does not appear straight when viewed from
                            the top.
                          </li>
                          <li>
                            The knife makes a “rougher” sound as it passes
                            through paper.
                          </li>
                          <li>
                            Nicks are visible on the cutting edge of the knife.
                          </li>
                        </ul>
                        <p>
                          <b>Cutting Stick</b>
                        </p>
                        <p>
                          A worn cutting stick can affect the cut quality of the
                          bottom sheets. When this happens, the cut stick can be
                          rotated. Usually, the stick should be rotated one or
                          two times between knife sharpenings.
                        </p>
                        <p>
                          There are 8 possible cut stick positions. The stick
                          can be rotated 4 times, and then turned end to end,
                          and rotated 4 times again.{' '}
                        </p>
                        <p>
                          <b>Bevel Angle</b>
                        </p>
                        <p>
                          Challenge recommends that bevel angles for knives be
                          in the range of 21° to 23°. In general, a 21° bevel
                          angle will provide better cut quality when cutting
                          soft paper (such as newsprint), recycled paper, or
                          bound books. However, 21° angle knives can become dull
                          sooner than 23° knives, which results in shorter knife
                          blade life. A knife with a 23° bevel angle, on the
                          other hand, will not dull as easily, and can provide
                          satisfactory results when cutting most types of paper.
                        </p>
                        <p>
                          <b>Helpful Suggestions</b>
                        </p>
                        <p>
                          It may be beneficial to purchase more than one set of
                          knives: one set beveled at 21° and the other at 23°.
                          Note: A set consists of 3 knives: one in the machine,
                          one as a back up, and one at the grinder.
                        </p>
                        <p>
                          If the machine seems to strain but the cut quality is
                          still good, reduce the pile height. You may also
                          carefully apply glycerin to the bevel when cutting
                          hard, coated paper. Tie a cloth to the end of a stick;
                          dip the stick in glycerin, and apply. Never apply by
                          hand! In lieu of glycerin you may lightly rub white
                          bar soap along the bevel. Lubrication will prolong the
                          life of your machine and reduce maintenance.
                        </p>
                        <p>
                          <b>Knife Care</b>
                        </p>
                        <ul>
                          <li>
                            To prevent corrosion, knives are coated with light
                            oil. It should be REMOVED WITH CARE.
                          </li>
                          <li>
                            While removing or installing a knife, be careful not
                            to allow the edge to bump against the machine. Nicks
                            will result.
                          </li>
                          <li>
                            If a knife bolt is damaged, replace it. Always keep
                            knife bolts securely tightened. Always use the
                            heavy-duty knife bolt washers provided by Challenge.
                            Failure to do so could result in scratching or
                            marring of the clamp face. Store knives in a dry
                            environment to prevent corrosion. Never attempt to
                            service a knife in any way.
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Paper Drilling Tips</Accordion.Header>
                  <Accordion.Body>
                    <Row className="justify-content-center">
                      <Col className="infoCol">
                        <ul>
                          <li>
                            Important! To prevent the drill from overheating,
                            always avoid drilling too slowly. The table should
                            be brought up as rapidly as possible allowing the
                            drills to easily cut through the paper. Also, adjust
                            the vertical table guide to return the table to the
                            down position as rapidly as possible to avoid
                            spinning the drills in the stock.
                          </li>
                          <li>
                            Slotted Holes - Instead of punching slotted holes
                            for five and seven hole universal binding work, save
                            time and cost by drilling a 1/2 inch diameter hole
                            in place of the slot. The slot is only intended to
                            allow the post or ring to be used in either
                            location, and the large hole permits this.
                          </li>
                          <li>
                            Plastic Bindings - Drilling holes for plastic
                            bindings, instead of punching them, is practical and
                            saves a great deal of time, particularly on long run
                            jobs.
                          </li>
                          <li>
                            Keep Drills Sharp - A dull drill is the major cause
                            of drill breakage and production tie-ups. Usually
                            after three hours of drilling, depending on the type
                            of paper being processed, the drill should be
                            sharpened. A dull drill results in poor quality
                            work.
                          </li>
                          <li>
                            Keep Drills Clean - A dirty and rusty drill will not
                            permit the free upward passage of the drill chips.
                            Pressure built up by a clogged drill will split or
                            break the drill. To keep it free from dirt or rust,
                            clean the drill of all chips after each use and
                            apply a light oil to the inside and outside. Drills
                            should be cleaned out immediately after each use.
                            This is particularly true if a coated or varnished
                            stock has been drilled. On these jobs the coating on
                            the chips frequently fuse the chips into one solid
                            mass when the drill cools, causing breakage the next
                            time the drill is used.
                          </li>
                          <li>
                            Lubricate Drills - Lubrication assists in the
                            passage of the chips and helps avoid overheating of
                            the drills. Use readily available stick lubricants
                            for this purpose. Hold the end of the stick against
                            the side of the rotating drill. Be sure to touch the
                            cutting edge with the lubricant also. Wipe off
                            excess oil before drilling. CARE MUST ALWAYS BE
                            TAKEN WHEN HANDLING DRILLS.
                          </li>
                          <li>
                            Keep Spindle Clean - Clean out the drill spindle
                            frequently. This will prevent any buildup in the
                            spindle of the drill.
                          </li>
                          <li>
                            Set the Drills Correctly - Do not cut too deeply
                            into the cutting block. The drill should just touch
                            the block and cleanly cut through the bottom sheet.
                            During drilling, do not set the drill deeper into
                            the block but change the position of the block
                            frequently. Drilling deeper into the block dulls the
                            drills quickly. Use a piece of chipboard underneath
                            your stock. This will make handling the stock easier
                            and will ensure that the last sheet is cut cleanly
                            through.
                          </li>
                          <li>
                            Check for Drill Wobble - If spindles are badly worn
                            or bent through misadjustment, have them replaced
                            immediately. A wobbly or loosely held drill will
                            break.
                          </li>
                          <li>
                            Check Your Drill Sharpener - The cutting edge of the
                            sharpening bit should be inspected frequently to
                            make certain that it is sharp and free of nicks.
                            Never let a drill drop onto the sharpening bit. It
                            will chip the sharpening edge. Use gentle pressure
                            when sharpening - let the sharpening bit do the
                            work. Check the sharpness of the drill after
                            sharpening. The cutting edge should be razor sharp.
                          </li>
                          <li>
                            Just a little time and effort taken with each use of
                            your paper drilling machine should result in trouble
                            free operation over many years.
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>Paper Jogging Tips</Accordion.Header>
                  <Accordion.Body>
                    <Row className="justify-content-center">
                      <Col className="infoCol">
                        <p>
                          The key to efficient paper jogging is to separate the
                          sheets with a cushion of air. This serves several
                          purposes:
                        </p>
                        <ul>
                          <li>
                            <p>
                              It eliminates static charges that will hold the
                              paper together.
                            </p>
                          </li>
                          <li>
                            <p>
                              It reduces friction between sheets and loosens any
                              sheets that are stuck together from clamping or
                              cutting.{' '}
                            </p>
                          </li>
                          <li>
                            <p>
                              It breaks any adhesion caused by wet or tacky ink
                              if you are jogging printed material.{' '}
                            </p>
                          </li>
                        </ul>
                        <p>
                          To do this you should always fan your paper before
                          jogging. As it is laying flat, grab the lift of paper
                          from opposite ends. Bend the edges of the lift in
                          towards each other. Tightly hold the edges of the bent
                          stack and then straighten it out. Release the edges of
                          the stack and let the sheets straighten out. Repeat
                          this procedure two or three times to loosen all
                          sheets.
                        </p>
                        <p>
                          When jogging large lifts of paper, instead of placing
                          the entire lift on the jogger at one time, proper
                          sheet alignment will be obtained faster if you feed
                          small lifts at a time, gradually building up to the
                          full amount.
                        </p>
                        <p>
                          Onionskin, tissue, and other types of light paper
                          require painstaking jogging for any type of accurate
                          work, especially cutting. These types of paper are
                          very difficult to jog and a few hints might make this
                          job a little easier and faster:
                        </p>
                        <ul>
                          <li>
                            <p>
                              When ordering these types of paper, specify the
                              grain to run lengthwise and jog with this
                              dimension towards the bottom of the table.
                            </p>
                          </li>
                          <li>
                            <p>
                              Lay pieces of chipboard above and below the lift
                              when jogging (this also makes it easier to handle
                              and maintain alignment).
                            </p>
                          </li>
                          <li>
                            <p>
                              Smooth out air pockets and waves and add a piece
                              of chipboard (if you haven't already done so)
                              before cutting.
                            </p>
                          </li>
                        </ul>
                        <p>
                          When jogging checks, data cards, or envelopes,
                          excessive noise can be reduced by gluing pieces of
                          felt inside the bottom edges of each compartment. Note
                          this will reduce jogging action slightly.
                        </p>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Cornering Tips</Accordion.Header>
                  <Accordion.Body>
                    <Row className="justify-content-center">
                      <Col className="infoCol">
                        <ul>
                          <li>
                            Keep the knife and die sharp and in good alignment.
                            Dull knife and poor adjustments lead to irregular
                            cuts. Knives should be carefully sharpened with a 35
                            degree bevel and finely honed (#32 micro cut or
                            better) to remove coarseness and burrs.
                          </li>
                          <li>
                            Exercise care when handling and changing the knives.
                          </li>
                          <li>
                            Firm placement of paper against the left and back
                            guides will ensure good corners.
                          </li>
                          <li>
                            Unusual noises and irregular action indicates a
                            maintenance check is due. Have the machine checked
                            by a qualified person.
                          </li>
                          <li>NEVER leave machine running unattended!</li>
                        </ul>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Safety_Info
