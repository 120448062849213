import React, { useEffect } from 'react'
import '../Support.scss'
import '../../../App.scss'
import { Button } from 'react-bootstrap'

const Parts = (): JSX.Element => {
  useEffect(() => {
    console.log('Rendering <Parts />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Support">
      <div className="s1">
        <h1>Replacement Parts</h1>
        <p>
          Replacement parts for supported machines are available for purchase
          through your local CMC dealer. To assist CMC certified technicians
          working on machines, The Challenge Machinery Company provides a
          generic 'spare parts list' for our most popular machines. If you are
          unable to locate the information you require in the list, please refer
          to your machine's manual or contact our support team.
        </p>
      </div>
      <div className="s2">
        <div>
          <h3>List of Common Spare Parts by Machine</h3>
          <a
            href={'/htm/Spare_Parts_List.htm'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>View List</Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Parts
