import React, { useEffect, useState } from 'react'
import './Contact.scss'
import '../../App.scss'
import { Link } from 'react-router-dom'
import * as URLS from '../../components/RouteList'
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Stack
} from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import ObjectState from '../../components/States'
import componentSwitch from '../../ComponentSwitch'

type UserSubmitForm = {
  department: string
  firstName: string
  lastName: string
  email: string
  confirmEmail: string
  company: string
  address: string
  city: string
  state: string
  zip: string
  country: string
  phone: string
  model: string
  serialNumber: string
  description: string
}

const Contact = (): JSX.Element => {
  const [department, setDepartment] = useState<string>('General Inquiries')
  const [submitState, setSubmitState] = useState<ObjectState>(ObjectState.SHOWN)

  const validationSchema = Yup.object().shape({
    department: Yup.string().required(),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('email is required').email('Email is invalid'),
    confirmEmail: Yup.string()
      .email()
      .required('Email confirmation is required')
      .oneOf([Yup.ref('email'), null], 'Emails do not match'),
    company: Yup.string(),
    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    country: Yup.string(),
    phone: Yup.string(),
    model: Yup.string(),
    serialNumber: Yup.string(),
    description: Yup.string()
      .required('Description is required')
      .max(8192, 'Description must not exceed 8192 characters')
  })

  useEffect(() => {
    console.log('Rendering <Contact />')
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    console.log('Department selected as:', department)
  }, [department])

  useEffect(() => {
    console.log('Submit state changed:', submitState)
  }, [submitState])

  const sendForm = (form: any, actions: any) => {
    console.log('sending POST request to backend:', form)
    setSubmitState(ObjectState.LOADING)
    axios
      .post(`https://script.challengemachinery.com/send-email.php`, form, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        timeout: 10000
      })
      .then(response => {
        console.log('POST results:', response.data)
        if (response.data === 1) {
          setSubmitState(ObjectState.HIDDEN)
          actions.resetForm({
            values: {
              firstName: '',
              lastName: '',
              email: '',
              confirmEmail: '',
              company: '',
              address: '',
              city: '',
              state: '',
              zip: '',
              country: '',
              phone: '',
              model: '',
              serialNumber: '',
              description: ''
            }
          })
        } else {
          setSubmitState(ObjectState.ERROR)
        }
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setSubmitState(ObjectState.ERROR)
      })
  }

  // const sendForm = (form: any, actions: any) => {
  //   console.log('sending POST request to backend:', form)
  //   setSubmitState(ObjectState.LOADING)
  //   axios
  //     .post(`${process.env.REACT_APP_SERVER_ADDR}/api/contact`, form, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       timeout: 10000
  //     })
  //     .then(response => {
  //       console.log('POST results:', response.data)
  //       if (response.data.response) {
  //         setSubmitState(ObjectState.HIDDEN)
  //         actions.resetForm({
  //           values: {
  //             firstName: '',
  //             lastName: '',
  //             email: '',
  //             confirmEmail: '',
  //             company: '',
  //             address: '',
  //             phone: '',
  //             model: '',
  //             serialNumber: '',
  //             description: ''
  //           }
  //         })
  //       } else {
  //         setSubmitState(ObjectState.ERROR)
  //       }
  //     })
  //     .catch(ex => {
  //       console.log('An error occured')
  //       if (ex.response) {
  //         console.log(ex.response.data)
  //         console.log(ex.response.status)
  //         console.log(ex.response.headers)
  //       } else if (ex.request) {
  //         console.log(ex.request)
  //       } else {
  //         console.log('Error', ex.message)
  //       }
  //       setSubmitState(ObjectState.ERROR)
  //     })
  // }

  const onSubmit = (data: UserSubmitForm, actions: any) => {
    let emailObject: Object = data
    switch (data.department) {
      case 'General Inquiries':
        emailObject = { ...emailObject, departmentId: 0 }
        break
      case 'Sales & Marketing':
        emailObject = { ...emailObject, departmentId: 1 }
        break
      case 'Technical Support':
        emailObject = { ...emailObject, departmentId: 2 }
        break
    }
    sendForm(emailObject, actions)
  }

  return (
    <div className="Contact">
      <div className="s1">
        <h1>Contact Us</h1>
        <p>
          The Challenge Machinery Company is here to help with any of your paper
          cutting and handling needs!
        </p>
        <p>
          For product or part inquiries, please locate and contact your closest
          dealer on the <Link to={`../${URLS.URL_DEALERS}`}>Dealer Finder</Link>{' '}
          page.
        </p>
      </div>
      <div className="s2">
        <Container fluid>
          <Row className="align-items-center ">
            <Col lg="6" xl="5" xxl="4">
              <span className="border-right">
                <h3>The Challenge Machinery Company</h3>
                <p>
                  6125 Norton Center Drive <br /> Norton Shores, MI 49441 USA
                </p>
                <p>
                  <b>Phone:</b> (231) 799-8484 <br /> <b>Fax:</b> (231) 798-1275
                </p>
                <p>
                  <b>Hours:</b> Monday - Friday <br /> 8:00 AM - 5:00 PM EST
                </p>
              </span>
            </Col>
            <Col>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1455.6265886572419!2d-86.21938039937596!3d43.141212561872585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x82c77b67cc219159!2zNDPCsDA4JzI4LjQiTiA4NsKwMTMnMDcuNCJX!5e0!3m2!1sen!2sus!4v1653312291582!5m2!1sen!2sus"
                title="Challenge Machinery"
                width="80%"
                height="500px"
                style={{
                  border: 0,
                  minWidth: '270px',
                  maxWidth: '1000px',
                  margin: '0'
                }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <hr />
              <h2>Send Us An Email</h2>
              <Formik
                validationSchema={validationSchema}
                onSubmit={(values, actions) => onSubmit(values, actions)}
                initialValues={{
                  department: 'General Inquiries',
                  firstName: '',
                  lastName: '',
                  email: '',
                  confirmEmail: '',
                  company: '',
                  address: '',
                  city: '',
                  state: '',
                  zip: '',
                  country: '',
                  phone: '',
                  model: '',
                  serialNumber: '',
                  description: ''
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="align-items-center">
                      <Col>
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>Department</Form.Label>
                          <Form.Select
                            value={values.department}
                            name="department"
                            aria-label={'Department'}
                            isInvalid={!!errors.department}
                            onChange={e => {
                              handleChange(e)
                              setDepartment(e.target.value)
                            }}
                          >
                            <option>General Inquiries</option>
                            <option>Sales {'&'} Marketing</option>
                            <option>Technical Support</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            First Name <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter first name"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Last Name <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            // required
                            type="text"
                            placeholder="Enter last name"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Email Address{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Confirm Email address{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            name="confirmEmail"
                            value={values.confirmEmail}
                            onChange={handleChange}
                            isInvalid={!!errors.confirmEmail}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmEmail}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Company/Organization</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your company name"
                            name="company"
                            value={values.company}
                            onChange={handleChange}
                            isInvalid={!!errors.company}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your phone number"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={!!errors.phone}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your address"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            isInvalid={!!errors.address}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="City"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            isInvalid={!!errors.city}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>State/Province</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="State/Province"
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                            isInvalid={!!errors.state}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Zip code"
                            name="zip"
                            value={values.zip}
                            onChange={handleChange}
                            isInvalid={!!errors.zip}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Country"
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                            isInvalid={!!errors.country}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {department === 'Technical Support' ? (
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Model Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter model name"
                              name="model"
                              value={values.model}
                              onChange={handleChange}
                              isInvalid={!!errors.model}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Serial Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter machine serial number"
                              name="serialNumber"
                              value={values.serialNumber}
                              onChange={handleChange}
                              isInvalid={!!errors.serialNumber}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Please provide a description of your request/issue{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            // required
                            rows={3}
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                          <Form.Text className="text-muted">
                            <span style={{ color: 'red' }}>*</span> = required
                            entry
                          </Form.Text>
                        </Form.Group>
                        {componentSwitch(submitState, switcher =>
                          switcher
                            .case(ObjectState.HIDDEN, () => (
                              <Stack direction="horizontal" gap={3}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled
                                >
                                  Submit
                                </Button>
                                <div className="vr"></div>
                                <p
                                  style={{
                                    textAlign: 'left',
                                    margin: 0,
                                    padding: 0
                                  }}
                                >
                                  Email successfully sent!
                                </p>
                              </Stack>
                            ))
                            .case(ObjectState.LOADING, () => (
                              <Stack direction="horizontal" gap={3}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled
                                >
                                  Submit
                                </Button>
                                <Spinner animation="border" role="status" />
                              </Stack>
                            ))
                            .case(ObjectState.SHOWN, () => (
                              <Button variant="primary" type="submit">
                                Submit
                              </Button>
                            ))
                            .case(ObjectState.ERROR, () => (
                              <Stack direction="horizontal" gap={3}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled
                                >
                                  Submit
                                </Button>
                                <div className="vr"></div>
                                <p
                                  style={{
                                    textAlign: 'left',
                                    margin: 0,
                                    padding: 0
                                  }}
                                >
                                  {/* <TH comment> - temporarily removed error since email DOES send even though script file returns an error  */}
                                  Email successfully sent!
                                </p>
                              </Stack>
                            ))
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Contact
