import React, { FC, useEffect, useState } from 'react'
import '../About.scss'
import '../../../App.scss'
import {
  Col,
  Container,
  Popover,
  OverlayTrigger,
  Row,
  ProgressBar,
  Form,
  Stack,
  Button
} from 'react-bootstrap'
import { ReactComponent as Dot } from './dot.svg'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'

const historyEvents = [
  {
    date: 1870,
    image: '/history/images/1870.jpg',
    imagewidth: '200px',
    text: 'It all began in September of 1870, when an electrotype foundry was established in Chicago to produce cuts for letterpress printing. It was founded under the name of Shniedewend & Lee.'
  },
  {
    date: 1871,
    image: '/history/images/1871.jpg',
    imagewidth: '200px',
    text: "The young company would have been wiped out by the Great Chicago Fire had it not been for the quick action of co-founder James L. Lee. He went into the burning city and their office to save important papers and electrotype plates just before the entire buliding was destroyed. Two weeks later, the firm reopened in a barn and in 1873, Shniedewend & Lee moved into larger quarters and opened a store for the wholesale and retail sale of printing machines, printer's supplies, and foundry type."
  },
  {
    date: 1887,
    image: '/history/images/1887.jpg',
    imagewidth: '200px',
    text: 'The original paper cutter factory established.'
  },
  {
    date: 1893,
    image: '/history/images/1893.jpg',
    imagewidth: '185px',
    text: 'Shniedewend & Lee Company was reorganized as The Challenge Machinery Company.'
  },
  {
    date: 1903,
    image: '/history/images/1903.jpg',
    imagewidth: '200px',
    text: 'The growing company moved to Grand Haven, Michigan across the same Great Lake whose water helped save the company over 33 years earlier.'
  },
  {
    date: 1907,
    image: '/history/images/1907.jpg',
    imagewidth: '200px',
    text: 'A foundry was added fulfilling a Lee family ambition to begin with raw material and produce a finished product, all under one roof. By this point in its history, it was clear that Challenge had become an important name in the printing equipment industry.'
  },
  {
    date: 1928,
    image: '/history/images/1928.jpg',
    imagewidth: '200px',
    text: 'The Precision Equipment Department, established in 1928 to serve the machine tool industry, was busy supplying the needs of war production customers.'
  },
  {
    date: 1930,
    image: '/history/images/1930.jpg',
    imagewidth: '250px',
    text: 'Challenge introduced the first paper drilling machine, a tremendous improvement over the paper punches.'
  },
  {
    date: 1940,
    image: '/history/images/1940.jpg',
    imagewidth: '200px',
    text: "The industry's first hydraulic paper cutter."
  },
  {
    date: 1950,
    image: '/history/images/1950.jpg',
    imagewidth: '200px',
    text: 'Challenge introduces the first hydraulic knife and clamp cutter.'
  },
  {
    date: 1960,
    image: '/history/images/1960.jpg',
    imagewidth: '200px',
    text: 'The original Champion paper cutter was introduced.'
  },
  {
    date: 1961,
    image: '/history/images/1961.jpg',
    imagewidth: '125px',
    text: 'The EH-3 drill was introduced.'
  },
  {
    date: 1965,
    image: '/history/images/1965.jpg',
    imagewidth: '97px',
    text: 'The first Challenge multi-spindle drill, with up to 10 heads, was introduced.'
  },
  {
    date: 1968,
    image: '/history/images/1968.jpg',
    imagewidth: '204px',
    text: 'Introduction of the Magna-Spacer, one of the first programmable backgauge controlled cutters.'
  },
  {
    date: 1972,
    image: '/history/images/1972.jpg',
    imagewidth: '101px',
    text: 'The Single Cornering Machine model was introduced.'
  },
  {
    date: 1978,
    image: '/history/images/1978.jpg',
    imagewidth: '175px',
    text: 'The Touch Command Memory Digital Spacer was introduced.'
  },
  {
    date: 1990,
    image: '/history/images/1990.jpg',
    imagewidth: '130px',
    text: 'The MS-5 multi-spindle drill was introduced.'
  },
  {
    date: 1994,
    image: '/history/images/1994.jpg',
    imagewidth: '150px',
    text: 'The DocuTrim was developed - a single-knife, programmable, 3-sided book trimmer.'
  },
  {
    date: 1996,
    image: '/history/images/1996.jpg',
    imagewidth: '155px',
    text: 'The first of the Titan model of paper cutters was introduced - the Titan 200.'
  },
  {
    date: 1997,
    image: '/history/images/1997.jpg',
    imagewidth: '200px',
    text: 'The Titan 265, Champion 370 and 305 paper cutters, the EH-3C paper drill, and the addition of narrow heads for the MS-5 were introduced at Print 97.'
  },
  {
    date: 2000,
    image: '/history/images/2000.jpg',
    imagewidth: '200px',
    text: 'Touch-screen color display XT programmer introduced for the Champion 305 & 370 paper cutters.'
  },
  {
    date: 2001,
    image: '/history/images/2001.jpg',
    imagewidth: '300px',
    text: 'The CMT-330 is introduced as the first fully automated, programmable three-knife trimmer for both inline and near line book trimming applications.'
  },
  {
    date: 2002,
    image: '/history/images/2002.jpg',
    imagewidth: '300px',
    text: 'After 99 years in Grand Haven, Michigan, Challenge moves eight miles north into a new facility in Norton Shores, MI.'
  },
  {
    date: 2006,
    image: '/history/images/2006.jpg',
    imagewidth: '250px',
    text: 'The Heavy-Duty line of gear-driven, large-size cutters is added to The Challenge products line-up.'
  },
  {
    date: 2007,
    image: '/history/images/2007.jpg',
    imagewidth: '100px',
    text: 'iJOG air assist, electro-mechanical paper joggers are introduced.'
  },
  {
    date: 2009,
    image: '/history/images/2009.png',
    imagewidth: '250px',
    text: 'The CMT-130 - a single knife 3-sided book trimmer - is developed and introduced as an affordable alternative to the 3-knife trimmers.'
  },
  {
    date: 2015,
    image: '/history/images/2015.jpg',
    imagewidth: '200px',
    text: 'Challenge Engineers developed the TC Touch-screen control and operating system that is now offered on several cutter and trimmer models across the product line.'
  },
  {
    date: 2020,
    image: '/history/images/2020.jpg',
    imagewidth: '380px',
    text: 'Challenge teams up with Canon and C.P.Bourg to offer the Book Smart Suite - A fully automated book production workflow.'
  },
  {
    date: 2022,
    image: '/history/images/2022.png',
    imagewidth: '250px',
    text: 'Challenge introduces the CHAMP 150, CHAMP 185, and CHAMP 185Pro lines of fully-hydraulic paper cutters - the most powerful paper cutters of their size.'
  }
]

type TimeLineForm = {
  start: number
  end: number
}

interface EventProps {
  size: number
  percent: number
  setDate: (year: number) => void
  date: number
  image?: string
  text: string
  placement: string
}

const EventDot: FC<EventProps> = props => {
  const [shown, setShown] = useState<boolean>(false)
  const popover = (
    <Popover id="popover-basic" style={{ width: 'auto', maxWidth: '400px' }}>
      <Popover.Header as="p">
        <b>{props.date}</b>
      </Popover.Header>
      <Popover.Body>
        <div style={{ textAlign: 'center' }}>
          {props.image ? <img src={props.image} alt="History"></img> : null}
        </div>
        <br />
        {props.text}
      </Popover.Body>
    </Popover>
  )

  return (
    <div className="timelineEvent">
      <OverlayTrigger
        onEnter={() => {
          props.setDate(props.percent)
          setShown(true)
        }}
        onExit={() => {
          props.setDate(0)
          setShown(false)
        }}
        trigger={['focus', 'hover']}
        placement="bottom-end"
        overlay={popover}
      >
        <Dot
          style={{ height: `${props.size}px` }}
          className={shown ? 'svgShown' : undefined}
        />
      </OverlayTrigger>
    </div>
  )
}

interface TimelineProps {
  startYear: number
  endYear: number
}

const TimeLine: FC<TimelineProps> = props => {
  const [progress, setProgress] = useState<number>(0)
  const h = 33

  return (
    <div style={{ position: 'relative', width: '100%', height: '200px' }}>
      {/* Define start and end dates */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ textAlign: 'left' }}>
          <h4>
            <b>| {props.startYear}</b>
          </h4>
        </div>
        <div style={{ textAlign: 'right' }}>
          <h4>
            <b>{props.endYear} |</b>
          </h4>
        </div>
      </div>
      {/* define a progress bar to represent a timeline */}
      <div style={{ position: 'absolute', width: '100%' }}>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            top: `${h / 3}px`
          }}
        >
          <ProgressBar now={progress} style={{ height: `${h / 3}px` }} />
        </div>
      </div>
      {/* define all events that appear on timeline */}
      {historyEvents.map((event, key) => {
        if (event.date < props.startYear || event.date > props.endYear)
          return null
        const percent =
          ((event.date - props.startYear) / (props.endYear - props.startYear)) *
          100
        return (
          <div
            key={key}
            style={{
              position: 'absolute',
              left: `calc(${percent}% - ${h / 2}px)`,
              height: `${h}px`
            }}
          >
            <EventDot
              size={h}
              percent={percent}
              image={event.image}
              text={event.text}
              date={event.date}
              setDate={setProgress}
              placement={key % 2 ? 'top' : 'bottom'}
            />
          </div>
        )
      })}
    </div>
  )
}

const History = (): JSX.Element => {
  const [start, setStart] = useState<number>(1870)
  const [end, setEnd] = useState<number>(new Date().getFullYear())

  const breakpoint = 850
  const validationSchema = Yup.object().shape({
    start: Yup.number()
      .min(1870, 'year must be greater than or equal to 1870')
      .max(
        new Date().getFullYear(),
        `year must be less than or equal to ${new Date().getFullYear()}`
      )
      .lessThan(Yup.ref('end'), 'year must be less than final year')
      .required('starting year required'),
    end: Yup.number()
      .min(1870, 'year must be greater than or equal to 1870')
      .max(
        new Date().getFullYear(),
        `year must be less than or equal to ${new Date().getFullYear()}`
      )
      .required('ending year required')
  })

  useEffect(() => {
    console.log('Rendering <History />')
    window.scrollTo(0, 0)
  }, [])

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const onSubmit = (data: TimeLineForm, actions: any) => {
    console.log('updated dates')
    setStart(data.start)
    setEnd(data.end)
  }

  return (
    <div className="About">
      <div className="s1">
        <h1>Challenge's History</h1>
        <p>
          The Challenge Machinery Company has a long lineage of excellence in
          machine quality and innovation. We are proud of our history and the
          company standards that have kept us in business for over 150 years.
        </p>
      </div>
      <div className="s2">
        <Container fluid>
          {dimensions.width >= breakpoint ? (
            <>
              <Row className="justify-content-center">
                <Col md="auto" lg={7}>
                  <h2>The Challenge Timeline</h2>
                  <p>
                    Hover over or click the events on the timeline to learn
                    about our company's history!
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="auto">
                  <Formik
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => onSubmit(values, actions)}
                    initialValues={{
                      start: start,
                      end: end
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <div className="formStack">
                          <Stack direction="horizontal" gap={3}>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                placeholder="1870"
                                name="start"
                                value={values.start}
                                onChange={handleChange}
                                isInvalid={!!errors.start}
                                style={{ width: '150px' }}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                style={{ width: '150px' }}
                              >
                                {errors.start}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <p>-</p>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                placeholder={`${new Date().getFullYear()}`}
                                name="end"
                                value={values.end}
                                onChange={handleChange}
                                isInvalid={!!errors.end}
                                style={{ width: '150px' }}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                style={{ width: '150px' }}
                              >
                                {errors.end}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                              Set Range
                            </Button>
                          </Stack>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col>
                  <TimeLine startYear={start} endYear={end} />
                </Col>
              </Row>
              <hr />
            </>
          ) : null}
          {historyEvents.map((event, key) => (
            <Row className="justify-content-center" key={key}>
              <Col md="auto" lg={7} key={key}>
                <h2 style={{ padding: '0', margin: '0' }}>
                  <b>{event.date}</b>
                </h2>
                <div style={{ textAlign: 'center' }}>
                  {event.image ? (
                    <img
                      src={event.image}
                      style={{ width: `${event.imagewidth}` }}
                      alt="History"
                    ></img>
                  ) : null}
                </div>
                <p style={{ textAlign: 'left' }}>{event.text}</p>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </div>
  )
}

export default History
