import React, { FC, useEffect, useState } from 'react'
import '../Support.scss'
import '../../../App.scss'
import axios from 'axios'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { ManualList } from './Machine_Info'
import ObjectState from '../../../components/States'

type DropList = {
  labelName: string
  selected?: string
  state: ObjectState
  list: Object[]
  updateFunction: (arg: string) => void
}

export const LoadDropList: FC<DropList> = props => {
  const updateFunc = props.updateFunction

  return (
    <>
      <Row className="align-items-center">
        <Form.Label column sm={4}>
          {props.labelName}
        </Form.Label>
        <Col>
          {props.state === ObjectState.LOADING ? (
            <Spinner animation="border" role="status" />
          ) : (
            <Form.Select
              aria-label={props.labelName}
              value={props.selected}
              onChange={e => {
                console.log('onChange triggered on:', props.labelName)
                updateFunc(e.target.value)
              }}
            >
              {props.list &&
                props.list.map((d: Object, key) => {
                  return (
                    <option key={key} value={Object.values(d)[0]}>
                      {Object.values(d)[0]}
                    </option>
                  )
                })}
            </Form.Select>
          )}
        </Col>
      </Row>
    </>
  )
}

export const getData = (
  query: Object | null,
  dataHook: (value: React.SetStateAction<Object[]>) => void,
  stateHook: (value: React.SetStateAction<ObjectState>) => void,
  loadQueries: (obj: Object) => void,
  defaultEntry: any
) => {
  console.log('performing api call with queries:', query)
  stateHook(ObjectState.LOADING)
  axios
    .get(`${process.env.REACT_APP_SERVER_ADDR}/api/support`, {
      params: query ? query : {},
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 10000
    })
    .then(response => {
      dataHook([defaultEntry].concat(response.data))
      stateHook(ObjectState.SHOWN)
    })
    .catch(ex => {
      console.log('An error occured')
      if (ex.response) {
        console.log(ex.response.data)
        console.log(ex.response.status)
        console.log(ex.response.headers)
      } else if (ex.request) {
        console.log(ex.request)
      } else {
        console.log('Error', ex.message)
      }
      dataHook([])
      stateHook(ObjectState.ERROR)
      loadQueries({})
    })
}

export const ManualSearch: FC<ManualList> = props => {
  const productTypeDefault = 'Select Product Type'
  const modelDefault = 'Select Model'
  const serialDefault = 'Select Serial Number'

  const [selectProduct, setSelectProduct] = useState<string>(productTypeDefault)
  const [selectModel, setModel] = useState<string>(modelDefault)
  // const [selectSerial, setSerial] = useState<string>(serialDefault)

  const [productState, setPState] = useState<ObjectState>(ObjectState.LOADING)
  const [modelState, setMState] = useState<ObjectState>(ObjectState.HIDDEN)
  const [serialState, setSState] = useState<ObjectState>(ObjectState.HIDDEN)

  const [productOptions, setProductOptions] = useState<Object[]>([])
  const [modelOptions, setModelOptions] = useState<Object[]>([])
  const [serialOptions, setSerialOptions] = useState<Object[]>([])

  useEffect(() => {
    console.log('Rendering <ManualSearch />')
  }, [])

  useEffect(() => {
    getData(null, setProductOptions, setPState, props.loadQueries, {
      Product_Type: productTypeDefault
    })
  }, [])

  const selectedProduct = (ptype: string): void => {
    console.log('product type selected as:', ptype)
    props.loadQueries({})
    setSelectProduct(ptype)
    if (ptype === productTypeDefault) {
      setMState(ObjectState.HIDDEN)
    } else {
      getData(
        { category: ptype },
        setModelOptions,
        setMState,
        props.loadQueries,
        {
          Model_Name: modelDefault
        }
      ) // load models
    }
    setSState(ObjectState.HIDDEN)
  }
  const selectedModel = (mtype: string): void => {
    console.log('model selected as:', mtype)
    props.loadQueries({})
    setModel(mtype)
    if (mtype === modelDefault) {
      setSState(ObjectState.HIDDEN)
    } else {
      getData(
        { category: selectProduct, model: mtype },
        setSerialOptions,
        setSState,
        props.loadQueries,
        {
          Serial_Number_Range: serialDefault
        }
      ) // load serial
    }
  }
  const selectedSerial = (stype: string): void => {
    console.log('serial number selected as:', stype)
    // setSerial(stype)
    if (stype !== serialDefault) {
      props.loadQueries({
        category: selectProduct,
        model: selectModel,
        serial_num: stype
      })
    } else {
      props.loadQueries({})
    }
  }

  return (
    <Form>
      {!productState && !modelState && !serialState ? (
        <>
          <p>Unable to load product information...</p>
          <p>Please try again later</p>
        </>
      ) : null}
      {productState > 0 ? (
        <LoadDropList
          labelName="Product Type"
          state={productState}
          list={productOptions}
          updateFunction={selectedProduct}
        />
      ) : null}
      {modelState > 0 ? (
        <LoadDropList
          labelName="Model Name"
          state={modelState}
          list={modelOptions}
          updateFunction={selectedModel}
        />
      ) : null}
      {serialState > 0 ? (
        <LoadDropList
          labelName="Serial Number"
          state={serialState}
          list={serialOptions}
          updateFunction={selectedSerial}
        />
      ) : null}
    </Form>
  )
}
