import React, { useEffect } from 'react'
import './Info.scss'
import '../../App.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Terms = (): JSX.Element => {
  useEffect(() => {
    console.log('Rendering <Terms />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Info">
      <div className="s1">
        <h1>Terms And Conditions</h1>
      </div>
      <div className="s2">
        <Container fluid>
          <Row className="justify-content-center">
            <Col className="infoCol">
              <p>
                <b>
                  PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE
                  CAREFULLY BEFORE USING THIS WEBSITE
                </b>
              </p>
              <p>
                Visitors of this site agree that access to and use of this site
                are subject to the following terms and conditions and other
                applicable law. If you do not agree to these terms and
                conditions, please do not use this site.
              </p>
              <h3>Privacy</h3>
              <p>
                The Challenge Machinery Company outlines our privacy terms on
                the privacy page. Please feel free to review our privacy
                commitment <Link to={'../privacy'}>HERE</Link>.
              </p>
              <h3>Disclaimer</h3>
              <p>
                The information on this website is provided "as is" and may
                include technical errors and inaccuracies despite our best
                attempt to provide updated and correct information. Therefore,
                The Challenge Machinery Company makes no warranty of any kind
                that information provided on this website is accurate, complete
                or up-to-date.
              </p>
              <h3>Copyright</h3>
              <p>
                All content hosted on this site including but not limited to
                text, graphics, videos, software, code, and documentation is
                copyrighted under applicable United States and international
                copyright laws. All rights reserved. Challenge holds rights to
                our name, logo, machine information, and images such that they
                may only be used by a thrid party entity under the explicit
                permission of The Challenge Machinery Company.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Terms
