import React, { useEffect } from 'react'
import './Support.scss'
import '../../App.scss'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as URLS from '../../components/RouteList'

const Support = (): JSX.Element => {
  useEffect(() => {
    console.log('Rendering <Support />')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Support">
      <div className="s1">
        <h1>Challenge Support</h1>
        <p>
          Challenge&apos;s technical support is known throughout the industry as
          being the best in the business. In keeping with that tradition, we are
          now pleased to offer free pdf downloads of our operator, service, and
          parts manuals. Also check out the Tips section to get the most out of
          your Challenge product.
        </p>
      </div>
      <hr />
      <div className="s2">
        <h1>Machine Information & Manuals</h1>
        <p>
          Challenge&apos;s technical support is known throughout the industry as
          being the best in the business. In keeping with that tradition, we are
          now pleased to offer free pdf downloads of our operator, service, and
          parts manuals for all factory-supported models and select
          non-supported models. This tool will also show you the{' '}
          <b>year of manufacture</b> and <b>current factory support status</b>{' '}
          of your product.
        </p>
        <Link to={`/${URLS.URL_MACHINEINFO}`}>
          <Button variant="primary" size="lg">
            View Page
          </Button>
        </Link>
      </div>
      <div className="s3">
        <h1>Part Finder</h1>
        <p>
          Save time looking up part numbers by using this list of commonly
          ordered spare parts and consumables for all of our current products
          and many discontinued products as well.
        </p>
        <Link to={`/${URLS.URL_PARTS}`}>
          <Button variant="light" size="lg">
            View Page
          </Button>
        </Link>
      </div>
      <div className="s2">
        <h1>Operating & Safety</h1>
        <p>
          With any industrial equipment, it is important to understand saftey
          protocols and best practices before operating heavy machinery. The
          Challenge Machinery Company provides free resources that teach
          operators the best practices and saftey guidelines when using our
          equipment.
        </p>
        <Link to={`/${URLS.URL_SAFETYINFO}`}>
          <Button variant="primary" size="lg">
            View Page
          </Button>
        </Link>
      </div>
      <div className="s3">
        <h1>Software Updates</h1>
        <p>
          Keep your machine software up-to-date by downloading the newest
          updates and patches directly from our website. Follow the included
          guides to install the patches on your machines.
        </p>
        <Link to={`/${URLS.URL_SOFTWARE}`}>
          <Button variant="light" size="lg">
            View Page
          </Button>
        </Link>
      </div>
      <div className="s2">
        <h1>Warranty</h1>
        <p>
          Protect your recently purchased equipment with the Challenge Machinery
          Warranty. Follow the easy-to-apply form on this page to register your
          equipment.
        </p>
        <Link to={`/${URLS.URL_WARRANTY}`}>
          <Button variant="primary" size="lg">
            View Page
          </Button>
        </Link>
      </div>
      <div className="s3">
        <h1>MSDS Downloads</h1>
        <p>
          Downloads are readily available for various oils, lubriacnts, and
          other materials use with your Challenge Machinery.
        </p>
        <Link to={`/${URLS.URL_MSDS}`}>
          <Button variant="light" size="lg">
            View Page
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default Support
