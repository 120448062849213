import React, { FC, useEffect, useState } from 'react'
import '../Support.scss'
import '../../../App.scss'
import { Col, Container, Row, Spinner, Stack, Table } from 'react-bootstrap'
import { ManualSearch } from './Manual_Search'
import axios from 'axios'
import componentSwitch from '../../../ComponentSwitch'
import ObjectState from '../../../components/States'

export type ManualList = {
  loadQueries: (obj: Object) => void
}

type TableProps = {
  id: React.Key
  link?: boolean
  email?: boolean
  value: string
}

const TableLink: FC<TableProps> = props => {
  return (
    <td key={props.id}>
      {props.link ? (
        props.value
      ) : props.email ? (
        <a href={`mailto:${props.value}`}>{props.value}</a>
      ) : (
        <a
          href={`/manuals/${props.value}.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.value}.pdf
        </a>
      )}
    </td>
  )
}

type ManualProps = {
  manuals: any
}

export const ManualTable: FC<ManualProps> = props => {
  type SupportProps = {
    str: string
  }

  const year = new Date().getFullYear()

  const SupportYear = (fprops: SupportProps) => {
    console.log(fprops)
    if (fprops.str[0] === '+') {
      return <td>Supported until end of {parseInt(fprops.str, 10) + year}</td>
    } else if (fprops.str[0].match(/[0-9]/i)) {
      return <td>Supported until end of {fprops.str}</td>
    }
    return <td>{fprops.str}</td>
  }

  return (
    <div>
      {props.manuals.map((data: any, i: number) => {
        return (
          <div key={i}>
            <div className="fixedTable" style={{ maxWidth: '500px' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th key={1}>Model Name</th>
                    <th key={2}>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {data.Model_Name ? (
                      <td key={1}>{data.Model_Name}</td>
                    ) : null}
                    {data.Description ? (
                      <td key={2}>{data.Description}</td>
                    ) : null}
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="fixedTable" style={{ maxWidth: '500px' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th key={3}>Year Manufactured</th>
                    <th key={4}>Support Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {data.Year_Built ? (
                      <td key={3}>{data.Year_Built}</td>
                    ) : null}
                    {data.Final_Support_Year ? (
                      <SupportYear key={4} str={data.Final_Support_Year} />
                    ) : null}
                  </tr>
                </tbody>
              </Table>
            </div>
            {data.Safety_Bulletin_PartNo ? (
              <div className="fixedTable" style={{ maxWidth: '500px' }}>
                <Table key={i} striped bordered hover>
                  <thead>
                    <tr>
                      {data.Safety_Bulletin_PartNo ? (
                        <th style={{ color: 'red' }} key={5}>
                          PRODUCT SAFETY BULLETIN EXISTS FOR THIS PRODUCT!
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {data.Safety_Bulletin_PartNo ? (
                        <TableLink
                          key={5}
                          id={5}
                          link={data.Safety_Bulletin_PartNo.includes(' ')}
                          value={data.Safety_Bulletin_PartNo}
                        />
                      ) : null}
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : null}
            <div className="fixedTable" style={{ maxWidth: '500px' }}>
              <Table key={i} striped bordered hover>
                <thead>
                  <tr>
                    {data.Manual1_Name ? (
                      <th key={6}>{data.Manual1_Name}</th>
                    ) : null}
                    {data.Manual2_Name ? (
                      <th key={7}>{data.Manual2_Name}</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {data.Manual1_PartNo ? (
                      <TableLink
                        key={6}
                        id={6}
                        link={data.Manual1_PartNo.includes(' ')}
                        email={data.Manual1_PartNo.includes('@')}
                        value={data.Manual1_PartNo}
                      />
                    ) : null}
                    {data.Manual2_PartNo ? (
                      <TableLink
                        key={7}
                        id={7}
                        link={data.Manual2_PartNo.includes(' ')}
                        value={data.Manual2_PartNo}
                      />
                    ) : null}
                  </tr>
                </tbody>
              </Table>
            </div>
            {data.Manual3_Name ? (
              <div className="fixedTable" style={{ maxWidth: '500px' }}>
                <Table key={i} striped bordered hover>
                  <thead>
                    <tr>
                      {data.Manual3_Name ? (
                        <th key={8}>{data.Manual3_Name}</th>
                      ) : null}
                      {data.Manual4_Name ? (
                        <th key={9}>{data.Manual4_Name}</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {data.Manual3_PartNo ? (
                        <TableLink
                          key={8}
                          id={8}
                          link={data.Manual3_PartNo.includes(' ')}
                          value={data.Manual3_PartNo}
                        />
                      ) : null}
                      {data.Manual4_PartNo ? (
                        <TableLink
                          key={9}
                          id={9}
                          link={data.Manual4_PartNo.includes(' ')}
                          value={data.Manual4_PartNo}
                        />
                      ) : null}
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

const Machine_Info = (): JSX.Element => {
  // const [queries, setQueries] = useState<Object>({})
  const [manuals, setManuals] = useState<Object[]>([])
  const [state, setState] = useState<ObjectState>(ObjectState.HIDDEN)

  useEffect(() => {
    console.log('Rendering <Machine_Info />')
    window.scrollTo(0, 0)
  }, [])

  const getManuals = (queries: Object): void => {
    if (Object.keys(queries).length <= 0) {
      setManuals([])
      setState(ObjectState.HIDDEN)
      return
    }
    setState(ObjectState.LOADING)
    console.log('performing FULL call with queries:', queries)
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDR}/api/support`, {
        params: queries,
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 10000
      })
      .then(response => {
        setManuals(response.data)
        setState(ObjectState.SHOWN)
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setManuals([])
        setState(ObjectState.ERROR)
      })
  }

  const updateQueries = (obj: Object): void => {
    if (Object.keys(obj).length) {
      getManuals(obj)
    } else {
      setState(ObjectState.HIDDEN)
    }
  }

  return (
    <div className="Support MachineInfo">
      <div className="s1">
        <h1>Manuals, Year of Manufacture, Support Status</h1>
        <p>
          Challenge&apos;s technical support is known throughout the industry as
          being the best in the business. In keeping with that tradition, we are
          now pleased to offer free pdf downloads of our operator, service, and
          parts manuals for all factory-supported models and select
          non-supported models. This tool will also show you the{' '}
          <b>year of manufacture</b> and <b>current factory support status</b>{' '}
          of your product.
        </p>
      </div>
      <div className="s2">
        <Container fluid>
          <Row
            style={{
              maxWidth: '500px',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <h2>Search for Product</h2>
          </Row>
          <Row
            style={{
              maxWidth: '500px',
              marginLeft: 'auto',
              marginRight: 'auto',
              minHeight: '150px'
            }}
          >
            <ManualSearch loadQueries={updateQueries} />
          </Row>
          <br />
          <hr />
          <br />
          <Row
            style={{
              maxWidth: '1800px',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            {componentSwitch(state, switcher =>
              switcher
                .case(ObjectState.HIDDEN, () => null)
                .case(ObjectState.LOADING, () => (
                  <Col>
                    <Spinner animation="border" role="status" />
                  </Col>
                ))
                .case(ObjectState.SHOWN, () => (
                  <ManualTable manuals={manuals} />
                ))
                .case(ObjectState.ERROR, () => (
                  <Col>
                    <p>Unable to load manual information...</p>
                  </Col>
                ))
            )}
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Machine_Info
