import React from 'react'
import '../../App.scss'
import './Products.scss'
import { Card, Col, Placeholder, Row } from 'react-bootstrap'

const ProductPlaceholder = () => {
  return (
    <>
      <Card className="border-2" border="primary">
        <Row>
          <Col id="img-col"></Col>
          <Col md={8}>
            <Card.Body>
              <Placeholder as={Card.Title} id="card-title" animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
              <Placeholder as={Card.Text} id="card-text" animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} />{' '}
                <Placeholder xs={4} /> <Placeholder xs={6} />{' '}
                <Placeholder xs={8} />
              </Placeholder>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ProductPlaceholder
