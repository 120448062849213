import React from 'react'

interface SwitchCases<T> {
  case: (value: T, result: () => React.ReactNode) => SwitchCases<T>
  default: (result: () => React.ReactNode) => SwitchCases<T>
}

export function componentSwitch<T>(
  value: T,
  cases: (cases: SwitchCases<T>) => void
) {
  var possibleCases: { value: T; result: () => React.ReactNode }[] = []
  var defaultResult: (() => React.ReactNode) | null = null

  var getSwitchCases: () => SwitchCases<T> = () => ({
    case: (value: T, result: () => React.ReactNode) => {
      possibleCases.push({ value: value, result })

      return getSwitchCases()
    },
    default: (result: () => React.ReactNode) => {
      defaultResult = result

      return getSwitchCases()
    }
  })

  // getSwitchCases is recursive and will add all possible cases to the possibleCases array and sets defaultResult.
  cases(getSwitchCases())

  // Check if one of the cases is met
  for (const possibleCase of possibleCases) {
    if (possibleCase.value === value) {
      return possibleCase.result()
    }
  }

  // Check if the default case is defined
  if (defaultResult) {
    // Typescript wrongly assumes that defaultResult is always null.
    var fixedDefaultResult = defaultResult as () => React.ReactNode

    return fixedDefaultResult()
  }

  // None of the cases were met and default was not defined.
  return undefined
}

export default componentSwitch
