import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './App.scss'
// bootstrap includes
import { Container, Row, Col, Nav } from 'react-bootstrap'

import { ReactComponent as Logo } from './images/challenge.svg'
import { ReactComponent as Twitter } from './images/twitter.svg'
import { ReactComponent as Facebook } from './images/facebook.svg'
import { ReactComponent as Instagram } from './images/instagram.svg'
import { ReactComponent as Linkedin } from './images/linkedin.svg'

const Socials = () => {
  return (
    <div className="social">
      <h2>Follow Us</h2>
      <div className="social-link">
        {/* <Twitter id="twitter" /> */}
        <a href="https://www.instagram.com/challengemachinery/">
          <Instagram id="instagram" />
        </a>
        {/* <Facebook id="facebook" /> */}
        <a href="https://www.linkedin.com/company/the-challenge-machinery-company/">
          <Linkedin id="linkedin" />
        </a>
      </div>
    </div>
  )
}

const DesktopFooter = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={2}>
          <Link to="/">
            <Logo className="sm_logo" />
          </Link>
        </Col>
        <Col>
          <div id="foot-nav">
            <Nav>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/products">
                Products
              </Nav.Link>
              <Nav.Link as={Link} to="/support">
                Support
              </Nav.Link>
              <Nav.Link as={Link} to="/dealers">
                Dealers
              </Nav.Link>
              <Nav.Link as={Link} to="/about">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/contact">
                Contact
              </Nav.Link>
            </Nav>
          </div>
          <div id="foot-content">
            <p>© 2022 The Challenge Machinery Company, USA.</p>
          </div>
          <div id="foot-nav">
            <Nav>
              <Nav.Link as={Link} to="/privacy">
                Privacy
              </Nav.Link>
              <Nav.Link as={Link} to="/terms">
                Terms
              </Nav.Link>
              <Nav.Link as={Link} to="/cookies">
                Cookies
              </Nav.Link>
            </Nav>
          </div>
        </Col>
        <Col xxs={3} sm={2}>
          <Socials />
        </Col>
      </Row>
    </Container>
  )
}

const MobileFooter = () => {
  return (
    <Container>
      <Row>
        <Col xs={2}>
          <Link to="/">
            <Logo className="sm_logo" />
          </Link>
        </Col>
        <Col>
          <div id="foot-nav">
            <Nav>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/products">
                Products
              </Nav.Link>
              <Nav.Link as={Link} to="/support">
                Support
              </Nav.Link>
              <Nav.Link as={Link} to="/dealers">
                Dealers
              </Nav.Link>
              <Nav.Link as={Link} to="/about">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/contact">
                Contact
              </Nav.Link>
            </Nav>
          </div>
          <div id="foot-content">
            <p>© 2021 The Challenge Machinery Company, USA.</p>
          </div>
          <div id="foot-nav">
            <Nav>
              <Nav.Link as={Link} to="/privacy">
                Privacy
              </Nav.Link>
              <Nav.Link as={Link} to="/terms">
                Terms
              </Nav.Link>
              <Nav.Link as={Link} to="/cookies">
                Cookies
              </Nav.Link>
            </Nav>
          </div>
        </Col>
        <Col xxs={3} sm={2}>
          <Socials />
        </Col>
      </Row>
    </Container>
  )
}

const Footer = (): JSX.Element => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const breakpoint = 992
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return dimensions.width < breakpoint ? <MobileFooter /> : <DesktopFooter />
}

export default Footer
