import React, { useEffect, useState } from 'react'
import './Warranty.scss'
import '../../../App.scss'
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
  Stack
} from 'react-bootstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import ObjectState from '../../../components/States'
import componentSwitch from '../../../ComponentSwitch'

const equipment = [
  {
    name: 'Paper Cutters',
    models: [
      'DYMII & iCutter Series',
      '37" machine',
      '30.5" machine',
      '26.5" machine',
      '23" machine',
      '20" machine',
      '19.3" machine',
      '18.5" machine',
      '15" machine'
    ]
  },
  {
    name: 'Book Trimmers',
    models: ['CMT 330', 'CMT 130', 'Docutrim', '3KT']
  },
  {
    name: 'Paper Drills',
    models: ['MS-10', 'MS-5', 'EH-3', 'JF', 'Handy-Drill']
  },
  {
    name: 'Cornering',
    models: ['SCM Hydraulic', 'SCM Manual']
  },
  {
    name: 'Joggers',
    models: ['iJOG', 'Companion MDJ', 'Combination Jogger', 'Handy-Jog']
  },
  {
    name: 'Padding Presses',
    models: ['Paddy Wagon', 'Handy-Padder', 'MiniPadder']
  },
  {
    name: 'Handling',
    models: ['Pak-Rak', 'Handy-Cart']
  }
]

type WarrantyForm = {
  model: string
  serialNumber: string
  dealerName: string
  purchaseDate: string
  installedBy: string
  dateInstalled: string
  purchaser: string
  company: string
  address: string
  phone: string
  fax: string
  email: string
  website: string
  promoCode: string
  isDescisionMaker: string
  descisionMakerEmail: string
  isSatisfiedInstalled: string
  isTrained: string
  isSatisfiedQuality: string
  otherPurchases: string[]
  otherInterests: string[]
  comments: string
  businessType: string
  numberEmployees: string
}

const Warranty = (): JSX.Element => {
  const [isDecisionMaker, setIsDecisionMaker] = useState<string>('')
  const [isOther, setIsOther] = useState<boolean>(false)
  const [models, setModels] = useState<Object[]>([])
  const [modelState, setModelState] = useState<ObjectState>(ObjectState.LOADING)
  const [submitState, setSubmitState] = useState<ObjectState>(ObjectState.SHOWN)

  const modelDefault = ' - Select a Model - '

  const validationSchema = Yup.object().shape({
    model: Yup.string()
      .notOneOf([modelDefault], 'Model selection required')
      .required('Model name required'),
    serialNumber: Yup.string().required('Serial number required'),
    dealerName: Yup.string().required("Dealer's name required"),
    purchaseDate: Yup.date().required('Purchase date required'),
    installedBy: Yup.string().required("Installer's name required"),
    dateInstalled: Yup.date().required('Installation date required'),
    purchaser: Yup.string().required("Purchaser's name required"),
    company: Yup.string().required('Company name required'),
    address: Yup.string().required('Customer address required'),
    phone: Yup.string().required('Customer phone number required'),
    fax: Yup.string(),
    email: Yup.string().required('email is required').email('Email is invalid'),
    website: Yup.string(),
    promoCode: Yup.string(),
    isDescisionMaker: Yup.string().required('Selection required'),
    descisionMakerEmail: Yup.string().when('isDescisionMaker', {
      is: 'no',
      then: Yup.string()
        .email('Email is invalid')
        .required('Email is required'),
      otherwise: Yup.string()
    }),
    isSatisfiedInstalled: Yup.string().required('Selection required'),
    isTrained: Yup.string().required('Selection required'),
    isSatisfiedQuality: Yup.string().required('Selection required'),
    otherPurchases: Yup.array().of(Yup.string()),
    otherInterests: Yup.array().of(Yup.string()),
    comments: Yup.string().max(
      2048,
      'Description must not exceed 2048 characters'
    ),
    businessType: Yup.string(),
    numberEmployees: Yup.string()
  })

  useEffect(() => {
    console.log('Rendering <Warranty />')
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getWarrantyItems()
  }, [])

  useEffect(() => {
    console.log('isDecisionMaker set to:', isDecisionMaker)
  }, [isDecisionMaker])

  const getWarrantyItems = () => {
    setModelState(ObjectState.LOADING)
    console.log('performing api call to get warranty products...')
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDR}/api/products/warranties`, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 10000
      })
      .then(response => {
        console.log('models response:', response.data)
        setModels(response.data)
        setModelState(ObjectState.SHOWN)
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setModels([])
        setModelState(ObjectState.ERROR)
      })
  }

  const onSubmit = (data: WarrantyForm, actions: any) => {
    console.log('sending POST request to backend:', data)
    setSubmitState(ObjectState.LOADING)
    axios
      .post(`${process.env.REACT_APP_SERVER_ADDR}/api/warranty`, data, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 10000
      })
      .then(response => {
        console.log('POST results:', response.data)
        if (response.data.response) {
          axios
            .post(
              `https://script.challengemachinery.com/send-warranty-email.php`,
              data,
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*'
                },
                timeout: 10000
              }
            )
            .then(response => {
              console.log('POST results:', response.data)
              if (response.data) {
                actions.resetForm()
                setSubmitState(ObjectState.HIDDEN)
              } else {
                actions.resetForm()
                setSubmitState(ObjectState.HIDDEN)
                console.log(
                  'warranty added to database, but email failed to send'
                )
              }
            })
            .catch(ex => {
              console.log('Email failed')
              if (ex.response) {
                console.log(ex.response.data)
                console.log(ex.response.status)
                console.log(ex.response.headers)
              } else if (ex.request) {
                console.log(ex.request)
              } else {
                console.log('Error', ex.message)
              }
              setSubmitState(ObjectState.ERROR)
            })
          actions.resetForm()
          setSubmitState(ObjectState.HIDDEN)
        } else {
          setSubmitState(ObjectState.ERROR)
        }
      })
      .catch(ex => {
        console.log('DB entry failed')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setSubmitState(ObjectState.ERROR)
      })
  }

  return (
    <div className="Warranty">
      <div className="s1">
        <h1>Warranty</h1>
        <p>
          Please fill out the form below to ensure your recently-installed
          machine is warrantied. This form is required for your machine to be
          warrantied.
        </p>
      </div>
      <div className="s2">
        <Container fluid>
          <Row className="align-items-center ">
            <Col>
              <Formik
                validationSchema={validationSchema}
                onSubmit={(values, actions) => onSubmit(values, actions)}
                initialValues={{
                  model: '',
                  serialNumber: '',
                  dealerName: '',
                  purchaseDate: '',
                  installedBy: '',
                  dateInstalled: '',
                  purchaser: '',
                  company: '',
                  address: '',
                  phone: '',
                  fax: '',
                  email: '',
                  website: '',
                  promoCode: '',
                  isDescisionMaker: '',
                  descisionMakerEmail: '',
                  isSatisfiedInstalled: '',
                  isTrained: '',
                  isSatisfiedQuality: '',
                  otherPurchases: [] as string[],
                  otherInterests: [] as string[],
                  comments: '',
                  businessType: '',
                  numberEmployees: ''
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                      <h2>Machine Information</h2>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Model <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          {componentSwitch(modelState, switcher =>
                            switcher
                              .case(ObjectState.HIDDEN, () => null)
                              .case(ObjectState.LOADING, () => (
                                <div id="inputText">
                                  <Spinner animation="border" role="status" />
                                </div>
                              ))
                              .case(ObjectState.SHOWN, () => (
                                <Form.Select
                                  value={values.model}
                                  name="model"
                                  aria-label={'Model'}
                                  onChange={handleChange}
                                  isInvalid={!!errors.model}
                                >
                                  <option value={modelDefault}>
                                    {modelDefault}
                                  </option>
                                  {models.map((model: Object, key) => (
                                    <option
                                      key={key}
                                      value={Object.values(model)[0]}
                                    >
                                      {Object.values(model)[0]}
                                    </option>
                                  ))}
                                </Form.Select>
                              ))
                              .case(ObjectState.ERROR, () => (
                                <Form.Select
                                  disabled
                                  value={values.model}
                                  name="model"
                                  aria-label={'Model'}
                                  onChange={handleChange}
                                  isInvalid={true}
                                >
                                  <option>
                                    Unable to load models at this time...
                                  </option>
                                </Form.Select>
                              ))
                          )}
                          <Form.Control.Feedback type="invalid">
                            {errors.model}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Serial Number{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter serial number name"
                            name="serialNumber"
                            value={values.serialNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.serialNumber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.serialNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Dealer's Name{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter dealer's name"
                            name="dealerName"
                            value={values.dealerName}
                            onChange={handleChange}
                            isInvalid={!!errors.dealerName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dealerName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Date Purchased (mm/dd/yyyy){' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="purchaseDate"
                            value={values.purchaseDate}
                            onChange={handleChange}
                            isInvalid={!!errors.purchaseDate}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.purchaseDate}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Installer's Name{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter installer's name"
                            name="installedBy"
                            value={values.installedBy}
                            onChange={handleChange}
                            isInvalid={!!errors.installedBy}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.installedBy}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Date Installed (mm/dd/yyy){' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="dateInstalled"
                            value={values.dateInstalled}
                            onChange={handleChange}
                            isInvalid={!!errors.dateInstalled}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dateInstalled}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <h2>Customer Information</h2>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Purchaser's Name{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter purchaser's name"
                            name="purchaser"
                            value={values.purchaser}
                            onChange={handleChange}
                            isInvalid={!!errors.purchaser}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.purchaser}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Company Name <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your company name"
                            name="company"
                            value={values.company}
                            onChange={handleChange}
                            isInvalid={!!errors.company}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.company}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Address <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your address"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            isInvalid={!!errors.address}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Phone <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your phone number"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={!!errors.phone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Email <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Fax</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your fax number"
                            name="fax"
                            value={values.fax}
                            onChange={handleChange}
                            isInvalid={!!errors.fax}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fax}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Website</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your website"
                            name="website"
                            value={values.website}
                            onChange={handleChange}
                            isInvalid={!!errors.website}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.website}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label
                            style={{ backgroundColor: 'rgba(255,229,100,.25)' }}
                          >
                            -Promo Code-
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter promo code"
                            name="promoCode"
                            value={values.promoCode}
                            onChange={handleChange}
                            isInvalid={!!errors.promoCode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.promoCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <h2>Survey</h2>
                    <Row>
                      <Col>
                        <label>
                          Are you the equipment-buying decision maker for your
                          organization? <span style={{ color: 'red' }}>*</span>
                        </label>
                        <br />
                        <Form.Check type="radio" id="Yes1">
                          <Form.Check.Input
                            type="radio"
                            name="isDescisionMaker"
                            value="yes"
                            checked={values.isDescisionMaker === 'yes'}
                            onChange={e => {
                              handleChange(e)
                              setIsDecisionMaker('yes')
                              values.descisionMakerEmail = ''
                            }}
                            isInvalid={!!errors.isDescisionMaker}
                          />
                          <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="No1">
                          <Form.Check.Input
                            type="radio"
                            name="isDescisionMaker"
                            value="no"
                            checked={values.isDescisionMaker === 'no'}
                            onChange={e => {
                              handleChange(e)
                              setIsDecisionMaker('no')
                            }}
                            isInvalid={!!errors.isDescisionMaker}
                          />
                          <Form.Check.Label>No</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.isDescisionMaker}
                          </Form.Control.Feedback>
                        </Form.Check>
                        <Form.Group>
                          <Form.Label>
                            If not, please provide this person's email address{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={
                              isDecisionMaker === 'yes' ||
                              isDecisionMaker === ''
                            }
                            placeholder="Enter email"
                            name="descisionMakerEmail"
                            value={values.descisionMakerEmail}
                            onChange={handleChange}
                            isInvalid={!!errors.descisionMakerEmail}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.descisionMakerEmail}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <label>
                          Was the equipment delivery and installation completed
                          to your satisfaction?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <br />
                        <Form.Check type="radio" id="Yes2">
                          <Form.Check.Input
                            type="radio"
                            name="isSatisfiedInstalled"
                            value="yes"
                            checked={values.isSatisfiedInstalled === 'yes'}
                            onChange={handleChange}
                            isInvalid={!!errors.isSatisfiedInstalled}
                          />
                          <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="No2">
                          <Form.Check.Input
                            type="radio"
                            name="isSatisfiedInstalled"
                            value="no"
                            checked={values.isSatisfiedInstalled === 'no'}
                            onChange={handleChange}
                            isInvalid={!!errors.isSatisfiedInstalled}
                          />
                          <Form.Check.Label>No</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.isSatisfiedInstalled}
                          </Form.Control.Feedback>
                        </Form.Check>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>
                          Did the installer conduct operator training for you
                          and your people?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <br />
                        <Form.Check type="radio" id="Yes3">
                          <Form.Check.Input
                            type="radio"
                            name="isTrained"
                            value="yes"
                            checked={values.isTrained === 'yes'}
                            onChange={handleChange}
                            isInvalid={!!errors.isTrained}
                          />
                          <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="No3">
                          <Form.Check.Input
                            type="radio"
                            name="isTrained"
                            value="no"
                            checked={values.isTrained === 'no'}
                            onChange={handleChange}
                            isInvalid={!!errors.isTrained}
                          />
                          <Form.Check.Label>No</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.isTrained}
                          </Form.Control.Feedback>
                        </Form.Check>
                      </Col>
                      <Col>
                        <label>
                          Are you satisfied with the quality of your equipment?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <br />
                        <Form.Check type="radio" id="Yes4">
                          <Form.Check.Input
                            type="radio"
                            name="isSatisfiedQuality"
                            value="yes"
                            checked={values.isSatisfiedQuality === 'yes'}
                            onChange={handleChange}
                            isInvalid={!!errors.isSatisfiedQuality}
                          />
                          <Form.Check.Label>Yes</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="No4">
                          <Form.Check.Input
                            type="radio"
                            name="isSatisfiedQuality"
                            value="no"
                            checked={values.isSatisfiedQuality === 'no'}
                            onChange={handleChange}
                            isInvalid={!!errors.isSatisfiedQuality}
                          />
                          <Form.Check.Label>No</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.isSatisfiedQuality}
                          </Form.Control.Feedback>
                        </Form.Check>
                      </Col>
                    </Row>
                    <hr />
                    <h2>Your CMC Equipment</h2>
                    <p>
                      Please select other Challenge Machinery equipment you have
                      purchased
                    </p>
                    <Row>
                      {equipment.map((data, key1) => (
                        <Col style={{ minWidth: '150px' }} key={key1}>
                          <b>{data.name}</b>
                          {data.models.map((model, key2) => (
                            <Form.Check
                              key={key2}
                              type="checkbox"
                              name="otherPurchases"
                              value={model}
                              checked={values.otherPurchases.includes(model)}
                              onChange={handleChange}
                              id={`check-${model}-${key2}-1`}
                              label={model}
                            />
                          ))}
                        </Col>
                      ))}
                    </Row>
                    <hr />
                    <h2>CMC Equipment That Interests You</h2>
                    <p>
                      Please select other Challenge Machinery equipment you may
                      be interested in purchasing in the future
                    </p>
                    <Row>
                      {equipment.map((data, key1) => (
                        <Col style={{ minWidth: '150px' }} key={key1}>
                          <b>{data.name}</b>
                          {data.models.map((model, key2) => (
                            <Form.Check
                              key={key2}
                              type="checkbox"
                              name="otherInterests"
                              value={model}
                              checked={values.otherInterests.includes(model)}
                              onChange={handleChange}
                              id={`check-${model}-${key2}-2`}
                              label={model}
                            />
                          ))}
                        </Col>
                      ))}
                    </Row>
                    <hr />
                    <h2>Information and Comments</h2>
                    <Row>
                      <Col>
                        <label>
                          <b>Type of Business</b>
                        </label>
                        <br />
                        <Form.Check type="radio" id="quick-printer">
                          <Form.Check.Input
                            type="radio"
                            name="businessType"
                            value="Quick Printer"
                            checked={values.businessType === 'Quick Printer'}
                            onChange={e => {
                              handleChange(e)
                              setIsOther(false)
                              values.businessType = ''
                            }}
                            isInvalid={!!errors.businessType}
                          />
                          <Form.Check.Label>Quick Printer</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="in-plant">
                          <Form.Check.Input
                            type="radio"
                            name="businessType"
                            value="In-Plant Printer"
                            checked={values.businessType === 'In-Plant Printer'}
                            onChange={e => {
                              handleChange(e)
                              setIsOther(false)
                              values.businessType = ''
                            }}
                            isInvalid={!!errors.businessType}
                          />
                          <Form.Check.Label>In-Plant Printer</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="general">
                          <Form.Check.Input
                            type="radio"
                            name="businessType"
                            value="General Commercial Printer"
                            checked={
                              values.businessType ===
                              'General Commercial Printer'
                            }
                            onChange={e => {
                              handleChange(e)
                              setIsOther(false)
                              values.businessType = ''
                            }}
                            isInvalid={!!errors.businessType}
                          />
                          <Form.Check.Label>
                            General Commercial Printer
                          </Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="government">
                          <Form.Check.Input
                            type="radio"
                            name="businessType"
                            value="Government"
                            checked={values.businessType === 'Government'}
                            onChange={e => {
                              handleChange(e)
                              setIsOther(false)
                              values.businessType = ''
                            }}
                            isInvalid={!!errors.businessType}
                          />
                          <Form.Check.Label>Government</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="education">
                          <Form.Check.Input
                            type="radio"
                            name="businessType"
                            value="Education"
                            checked={values.businessType === 'Education'}
                            onChange={e => {
                              handleChange(e)
                              setIsOther(false)
                              values.businessType = ''
                            }}
                            isInvalid={!!errors.businessType}
                          />
                          <Form.Check.Label>Education</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="other">
                          <Form.Check.Input
                            type="radio"
                            name="businessType"
                            value="Other"
                            checked={values.businessType === 'Other'}
                            onChange={e => {
                              handleChange(e)
                              setIsOther(true)
                              values.businessType = ''
                            }}
                            isInvalid={!!errors.businessType}
                          />
                          <Form.Check.Label>
                            Other{' '}
                            <Form.Group>
                              <Form.Control
                                type="text"
                                disabled={!isOther}
                                placeholder="Enter business type"
                                name="businessType"
                                value={values.businessType}
                                onChange={handleChange}
                                isInvalid={!!errors.businessType}
                              />
                            </Form.Group>
                          </Form.Check.Label>
                        </Form.Check>
                      </Col>
                      <Col>
                        <label>
                          <b>Number of Employees</b>
                        </label>
                        <br />
                        <Form.Check type="radio" id="1_4">
                          <Form.Check.Input
                            type="radio"
                            name="numberEmployees"
                            value="1 - 4"
                            checked={values.numberEmployees === '1 - 4'}
                            onChange={handleChange}
                            isInvalid={!!errors.numberEmployees}
                          />
                          <Form.Check.Label>1 - 4</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="5_9">
                          <Form.Check.Input
                            type="radio"
                            name="numberEmployees"
                            value="5 - 9"
                            checked={values.numberEmployees === '5 - 9'}
                            onChange={handleChange}
                            isInvalid={!!errors.numberEmployees}
                          />
                          <Form.Check.Label>5 - 9</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="10_24">
                          <Form.Check.Input
                            type="radio"
                            name="numberEmployees"
                            value="10 - 24"
                            checked={values.numberEmployees === '10 - 24'}
                            onChange={handleChange}
                            isInvalid={!!errors.numberEmployees}
                          />
                          <Form.Check.Label>10 - 24</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="25_49">
                          <Form.Check.Input
                            type="radio"
                            name="numberEmployees"
                            value="25 - 49"
                            checked={values.numberEmployees === '25 - 49'}
                            onChange={handleChange}
                            isInvalid={!!errors.numberEmployees}
                          />
                          <Form.Check.Label>25 - 49</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id="50+">
                          <Form.Check.Input
                            type="radio"
                            name="numberEmployees"
                            value="50+"
                            checked={values.numberEmployees === '50+'}
                            onChange={handleChange}
                            isInvalid={!!errors.numberEmployees}
                          />
                          <Form.Check.Label>50+</Form.Check.Label>
                        </Form.Check>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Please provide any comments about your machine or
                            purchase
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            // required
                            rows={3}
                            name="comments"
                            value={values.comments}
                            onChange={handleChange}
                            isInvalid={!!errors.comments}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.comments}
                          </Form.Control.Feedback>
                          <Form.Text className="text-muted">
                            <span style={{ color: 'red' }}>*</span> = required
                            entry
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {componentSwitch(submitState, switcher =>
                          switcher
                            .case(ObjectState.HIDDEN, () => (
                              <Stack direction="horizontal" gap={3}>
                                <Button variant="primary" type="submit">
                                  Submit
                                </Button>
                                <div className="vr"></div>
                                <p
                                  style={{
                                    textAlign: 'left',
                                    margin: 0,
                                    padding: 0
                                  }}
                                >
                                  Warranty successfully sent!
                                </p>
                              </Stack>
                            ))
                            .case(ObjectState.LOADING, () => (
                              <Stack direction="horizontal" gap={3}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled
                                >
                                  Submit
                                </Button>
                                <Spinner animation="border" role="status" />
                              </Stack>
                            ))
                            .case(ObjectState.SHOWN, () => (
                              <Button variant="primary" type="submit">
                                Submit
                              </Button>
                            ))
                            .case(ObjectState.ERROR, () => (
                              <Stack direction="horizontal" gap={3}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled
                                >
                                  Submit
                                </Button>
                                <div className="vr"></div>
                                <p
                                  style={{
                                    textAlign: 'left',
                                    margin: 0,
                                    padding: 0
                                  }}
                                >
                                  {/* <TH comment> - temporarily removed error since email does send even though script file returns an error  */}
                                  Warranty successfully sent!
                                </p>
                              </Stack>
                            ))
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Warranty
