import React, { FC, useEffect, useState } from 'react'
import '../../App.scss'
import './Products.scss'
import EProduct from './Components/ProductTypes'
import ObjectState from '../../components/States'
import componentSwitch from '../../ComponentSwitch'
import { getData, LoadDropList } from '../Support/Machine_Info/Manual_Search'
// bootstrap includes
import {
  Card,
  Row,
  Col,
  Spinner,
  Button,
  Carousel,
  Tabs,
  Tab,
  Container,
  ListGroup,
  Table
} from 'react-bootstrap'
// product includes
import ProductPlaceholder from './Placeholders'
import { Link, useParams } from 'react-router-dom'
import { ProductTypeId, productTypes } from '../../AppRoutes'
import axios from 'axios'
import { ManualTable } from '../Support/Machine_Info/Machine_Info'

// ------------ Loading Symbol Render Image Component -----------------

type TCardImg = {
  imgUrl: string
  imgAlt: string
}

const RenderedCardImg: FC<TCardImg> = ({ imgUrl, imgAlt }) => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [imgOpacity, setOpacity] = useState<number>(0)
  const [imgWidth, setWidth] = useState<number>(0)

  useEffect(() => {
    setLoading(true)
    setOpacity(0)
    setWidth(0)
  }, [imgUrl])

  const loadComplete = () => {
    // console.log(`${imgUrl} Loaded`)
    setLoading(false)
    setOpacity(1)
    setWidth(100)
  }

  return (
    <div className="containerImage">
      <Card.Img
        className={!isLoading ? 'cardLoad' : ''}
        src={imgUrl}
        alt={imgAlt}
        onLoad={() => loadComplete()}
        onError={() => loadComplete()}
        style={{
          opacity: imgOpacity,
          width: imgWidth ? 'auto' : 0,
          maxWidth: '100%',
          padding: '5px'
        }}
      />
      {isLoading ? <Spinner animation="border" role="status" /> : undefined}
    </div>
  )
}

// ------------ All Products Page -----------------

const Products = (): JSX.Element => {
  const [products] = useState<ProductTypeId[]>(productTypes)

  useEffect(() => {
    console.log('Rendering <Products />')
    window.scrollTo(0, 0)
  }, [])

  const renderProducts = () => (
    <>
      {products.map((category: ProductTypeId, _key) => (
        <Card key={_key} className="border-2" border="primary">
          <Link id="card-item" to={category.path}>
            <Row>
              <Col md={4} id="img-col">
                <RenderedCardImg
                  imgUrl={`/products/images/${category.path}/main.jpg`}
                  imgAlt={category.dispName}
                />
              </Col>
              <Col md={8}>
                <Card.Body>
                  <Card.Title id="card-title">{category.dispName}</Card.Title>
                  <Card.Text id="card-text">{category.s_description}</Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Link>
        </Card>
      ))}
    </>
  )

  return (
    <div className="Products">
      <div className="ProductsHeader">
        <h1>Products</h1>
        <p>
          Since Challenge first started making guillotine paper cutters in 1887,
          we have been recognized as the leader in graphic material finishing
          technology. Since then, The Challenge Machinery Company has expanded
          our product lines to accommodate for industry applications such as
          paper drilling, padding, and handling.
        </p>
        <p>
          Download the latest&nbsp;
          <a
            href={`/products/documents/Challenge_Equipment_Catalog-Full_Line.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Challenge Equipment Catalog
          </a>
        </p>
      </div>
      <hr />
      {renderProducts()}
    </div>
  )
}
export default Products

// ------------ Type of Product Page -----------------

type TProductProps = {
  idType: ProductTypeId
}

const RenderProducts: FC<TProductProps> = ({ idType }) => {
  const [products, setProducts] = useState<Object[]>([])
  const [productsState, setProductsState] = useState<ObjectState>(
    ObjectState.HIDDEN
  )

  useEffect(() => {
    // This effect is executed every new render
    console.log(`Rendering All Products of type [${idType.dispName}]`)
    getProducts()
  }, [idType])

  const getProducts = () => {
    setProductsState(ObjectState.LOADING)
    console.log('performing api call to get product of type ...')
    axios
      .get(
        `${process.env.REACT_APP_SERVER_ADDR}/api/products/${idType.apiType}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          timeout: 10000
        }
      )
      .then(response => {
        setProducts(response.data)
        setProductsState(ObjectState.SHOWN)
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setProducts([])
        setProductsState(ObjectState.ERROR)
      })
  }

  const renderProducts = () => (
    <>
      {componentSwitch(productsState, switcher =>
        switcher
          .case(ObjectState.HIDDEN, () => null)
          .case(ObjectState.LOADING, () => (
            <>
              <ProductPlaceholder />
              <ProductPlaceholder />
            </>
          ))
          .case(ObjectState.SHOWN, () =>
            products.map((product: any, key) => (
              <Card key={key} className="border-2" border="primary">
                <Link id="card-item" to={product.url}>
                  <Row>
                    <Col md={4} id="img-col">
                      <RenderedCardImg
                        imgUrl={`/products/images/${idType.path}/${product.image}`}
                        imgAlt={product.name}
                      />
                    </Col>
                    <Col md={8}>
                      <Card.Body>
                        <Card.Title id="card-title">{product.name}</Card.Title>
                        <Card.Text id="card-text">
                          {product.description}
                        </Card.Text>
                      </Card.Body>
                    </Col>
                  </Row>
                </Link>
              </Card>
            ))
          )
          .case(ObjectState.ERROR, () => (
            <p>Unable to load product information at this time...</p>
          ))
      )}
    </>
  )

  return <>{renderProducts()}</>
}

// Functional Component for displaying each sub product type page
export const ProductTypes = (): JSX.Element => {
  const params = useParams()

  useEffect(() => {
    console.log('Rendering <ProductTypes />')
    window.scrollTo(0, 0)
  }, [])

  const pType = productTypes.find(
    category => category.path === params.productType
  )
  let pListObject = pType ? <RenderProducts idType={pType} /> : null

  return (
    <div className="Products">
      {pType !== undefined && pType.id !== EProduct.UNKNOWN ? (
        <>
          <div className="ProductsHeader">
            <h1>{pType.dispName}</h1>
            <p>{pType.description}</p>
          </div>
          <hr />
          {pListObject}
        </>
      ) : (
        <>
          <h1>Error-404</h1>
          <h3>Could not find matching product type!</h3>
        </>
      )}
    </div>
  )
}

// ------------ Individual Product Page -----------------
// Functional Component for displaying unique product information page
export const ProductDisplay: FC = () => {
  const serialDefault = 'Select Serial Number'

  const [product, setProduct] = useState<any>(null)
  const [productState, setProductState] = useState<ObjectState>(
    ObjectState.HIDDEN
  )
  const [serialSelected, setSSelected] = useState<string>(serialDefault)
  const [serialState, setSState] = useState<ObjectState>(ObjectState.HIDDEN)
  const [serialOptions, setSerialOptions] = useState<Object[]>([])
  const [manuals, setManuals] = useState<Object[]>([])
  const [manualsState, setManualsState] = useState<ObjectState>(
    ObjectState.HIDDEN
  )

  const params = useParams()

  useEffect(() => {
    console.log('Rendering <ProductDisplay />')
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getProduct({ url: params.productId })
  }, [params])

  useEffect(() => {
    console.log('serialOptions Hook called')
    if (serialOptions.length > 0) {
      if (product) {
        console.log('- setting serial to default -')
        setSSelected(product.resources.manual_lookup.serial)
      }
    }
  }, [serialOptions.length])

  useEffect(() => {
    console.log('Selected serial hook called:', serialSelected)
    if (product) {
      if (serialSelected !== serialDefault) {
        updateManuals({
          category: product.resources.manual_lookup.category,
          model: product.resources.manual_lookup.model,
          serial_num: serialSelected
        })
      } else {
        setManualsState(ObjectState.HIDDEN)
      }
    }
  }, [serialSelected])

  const getProduct = (query: Object) => {
    setProductState(ObjectState.LOADING)
    console.log('performing api call to get product with query:', query)
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDR}/api/products`, {
        params: query,
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 10000
      })
      .then(response => {
        if (response.data.error) {
          console.log('results returned with error:', response.data)
          setProductState(ObjectState.ERROR)
        } else {
          console.log('results returned successfully:', response.data)
          setProduct(response.data)
          setProductState(ObjectState.SHOWN)
        }
      })
      .catch(ex => {
        console.log('An error occured')
        if (ex.response) {
          console.log(ex.response.data)
          console.log(ex.response.status)
          console.log(ex.response.headers)
        } else if (ex.request) {
          console.log(ex.request)
        } else {
          console.log('Error', ex.message)
        }
        setProduct([])
        setProductState(ObjectState.ERROR)
      })
  }

  const updateManuals = (obj: Object): void => {
    if (Object.keys(obj).length) {
      setManualsState(ObjectState.LOADING)
      console.log('performing FULL call with queries:', obj)
      axios
        .get(`${process.env.REACT_APP_SERVER_ADDR}/api/support`, {
          params: obj,
          headers: {
            'Content-Type': 'application/json'
          },
          timeout: 10000
        })
        .then(response => {
          setManuals(response.data)
          setManualsState(ObjectState.SHOWN)
        })
        .catch(ex => {
          console.log('An error occured')
          if (ex.response) {
            console.log(ex.response.data)
            console.log(ex.response.status)
            console.log(ex.response.headers)
          } else if (ex.request) {
            console.log(ex.request)
          } else {
            console.log('Error', ex.message)
          }
          // setManuals([])
          // setManualsState(ObjectState.ERROR)
        })
    } else {
      setManualsState(ObjectState.HIDDEN)
    }
  }

  const handleTabSelect = (k: string | null): void => {
    console.log('tab selected:', k)
    if (k === 'third') {
      // perform queries to get manual information
      if (
        product.resources.manual_lookup &&
        product.resources.manual_lookup.category &&
        product.resources.manual_lookup.model
      ) {
        getData(
          {
            category: product.resources.manual_lookup.category,
            model: product.resources.manual_lookup.model
          },
          setSerialOptions,
          setSState,
          updateManuals,
          {
            Serial_Number_Range: serialDefault
          }
        )
      }
    }
  }

  const selectedSerial = (stype: string): void => {
    console.log('serial number selected as:', stype)
    setSSelected(stype)
    // performing the serial selection API call is handled by the hook
  }

  return (
    <div className="Products">
      {componentSwitch(productState, switcher =>
        switcher
          .case(ObjectState.HIDDEN, () => null)
          .case(ObjectState.LOADING, () => (
            <Row className="align-content-center" style={{ height: '80vh' }}>
              <Col>
                <Spinner animation="border" role="status" />
              </Col>
            </Row>
          ))
          .case(ObjectState.SHOWN, () =>
            product.name ? (
              <div className="ppage">
                <Container fluid>
                  <Row className="justify-content-center" id="product_s4">
                    <Col md="auto">
                      <h1>{product.name}</h1>
                      <hr />
                    </Col>
                  </Row>
                  <Row className="justify-content-center" id="product_s2">
                    <Col sm={10} md={9} lg={8} xxl={7}>
                      {product.images !== undefined &&
                      product.images.length > 0 ? (
                        <>
                          <Carousel variant="dark" interval={null}>
                            {product.images.map((image: any, key: any) => (
                              <Carousel.Item key={key}>
                                <div className="carouselImg">
                                  <img
                                    className="d-block"
                                    src={`/products/images/${params.productType}/${image.image}`}
                                    alt={image.alt}
                                  />
                                </div>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                          <hr />
                        </>
                      ) : (
                        <p>No images found</p>
                      )}
                    </Col>
                  </Row>
                  {product.description && product.description.length > 0 ? (
                    <>
                      <Row className="justify-content-center">
                        <Col
                          sm={10}
                          md={9}
                          lg={8}
                          xxl={7}
                          style={{ textAlign: 'left' }}
                        >
                          <h2>Overview</h2>
                          {product.description.map((block: any, key: any) => (
                            <div key={key}>
                              <br />
                              {block.image && block.text ? (
                                <div className="product-description">
                                  <img
                                    src={`/products/images/${params.productType}/${block.image}`}
                                    alt={block.image}
                                    style={{
                                      maxWidth: '1000px'
                                    }}
                                  />
                                  <p>{block.text}</p>
                                </div>
                              ) : (
                                <>
                                  {block.image ? (
                                    <div className="product-description">
                                      <img
                                        src={`/products/images/${params.productType}/${block.image}`}
                                        alt={block.image}
                                      />
                                    </div>
                                  ) : null}
                                  {block.text ? <p>{block.text}</p> : null}
                                </>
                              )}
                            </div>
                          ))}
                          <br />
                          <hr />
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {product.videos && product.videos.length > 0 ? (
                    <>
                      <Row className="justify-content-center">
                        <Col sm={10} md={9} lg={8} xxl={7}>
                          <h2>Videos</h2>
                          {product.videos
                            ? product.videos.map((video: any, key: any) => (
                                <div key={key}>
                                  <br />
                                  <iframe
                                    key={key}
                                    title={video.alt}
                                    width="80%"
                                    height="400"
                                    style={{ maxWidth: '700px' }}
                                    src={video.link}
                                  ></iframe>
                                  <p>{video.alt}</p>
                                </div>
                              ))
                            : null}
                          <hr />
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {product.variants && product.variants.length > 0 ? (
                    <>
                      <Row className="justify-content-center">
                        <Col sm={10} md={9} lg={8} xxl={7}>
                          <h2>Similar Products</h2>
                          <br />
                          <Row className="justify-content-center">
                            {product.variants.map((variant: any, key: any) => (
                              <Col key={key} md="auto">
                                <Card style={{ width: '22rem' }}>
                                  <Link id="card-item" to={variant.link}>
                                    <Card.Img
                                      variant="top"
                                      src={`/products/images/${params.productType}/${variant.image}`}
                                      alt={variant.image}
                                      style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        padding: '5px',
                                        maxHeight: '300px',
                                        width: 'auto',
                                        maxWidth: '100%'
                                      }}
                                    />
                                  </Link>
                                  <Card.Body>
                                    <Card.Title>{variant.name}</Card.Title>
                                    <Card.Text>{variant.description}</Card.Text>
                                    <Link id="card-item" to={variant.link}>
                                      <Button variant="primary">
                                        View Product
                                      </Button>
                                    </Link>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                          <br />
                          <hr />
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Row className="justify-content-center">
                    <Col className="nav-justified" sm md={10} lg={9} xxl={8}>
                      <Tabs
                        defaultActiveKey={'first'}
                        onSelect={k => handleTabSelect(k)}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab eventKey="first" title="Features">
                          <Row className="justify-content-center">
                            <Col md="auto">
                              {product.features.blocks.map(
                                (block: any, key: any) => (
                                  <div key={key}>
                                    <h3>{block.header}</h3>
                                    <ListGroup>
                                      {block.bullets.map(
                                        (bullet: string, key2: number) => (
                                          <ListGroup.Item key={key2}>
                                            {bullet}
                                          </ListGroup.Item>
                                        )
                                      )}
                                    </ListGroup>
                                    {block.images.map(
                                      (image: any, key2: number) => (
                                        <img
                                          key={key2}
                                          src={`/products/images/${image.image}`}
                                          alt={image.alt}
                                          style={{
                                            padding: '5px'
                                          }}
                                        />
                                      )
                                    )}
                                    <p>{block.footnote}</p>
                                  </div>
                                )
                              )}
                            </Col>
                          </Row>
                        </Tab>
                        <Tab eventKey="second" title="Specifications">
                          <Row className="justify-content-center">
                            <Col md="auto">
                              {product.specs.tables.map(
                                (table: any, key: any) => (
                                  <div key={key}>
                                    <h3>{table.name}</h3>
                                    <Table striped bordered hover>
                                      <tbody>
                                        {table.rows.map(
                                          (item: any, key2: number) => (
                                            <tr key={key2}>
                                              <td key={0}>{item.key}</td>
                                              <td key={1}>{item.value}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                )
                              )}
                            </Col>
                          </Row>
                          {product.specs.images.map(
                            (image: any, key: number) => (
                              <div className="scrollImage" key={key}>
                                <img
                                  src={`/products/images/${image.image}`}
                                  alt={image.alt}
                                />
                              </div>
                            )
                          )}
                          <p>{product.specs.footnote}</p>
                        </Tab>
                        <Tab
                          eventKey="third"
                          title="Additional Resources"
                          onSelect={() => {
                            console.log('tab selected')
                          }}
                        >
                          <Row className="justify-content-center">
                            <Col md="auto">
                              {product.resources.tables.map(
                                (table: any, key: any) => (
                                  <div key={key}>
                                    <h3>{table.name}</h3>
                                    <Table striped bordered hover>
                                      <tbody>
                                        {table.rows.map(
                                          (item: any, key2: number) => (
                                            <tr key={key2}>
                                              <td key={0}>{item.key}</td>
                                              <td key={1}>
                                                <a
                                                  href={`/products/documents/${item.link}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {item.value}
                                                </a>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                )
                              )}
                            </Col>
                          </Row>
                          <br />
                          {/* Load other manuals based on serial number */}
                          {product.resources.manual_lookup &&
                          product.resources.manual_lookup.category &&
                          product.resources.manual_lookup.model ? (
                            <>
                              <Row className="justify-content-center">
                                <Col md="auto">
                                  <h3>Operator/Service Manuals</h3>
                                  {serialState === ObjectState.ERROR ? (
                                    <p>Unable to load serial information...</p>
                                  ) : (
                                    <LoadDropList
                                      labelName="Serial Number"
                                      selected={serialSelected}
                                      state={serialState}
                                      list={serialOptions}
                                      updateFunction={selectedSerial}
                                    />
                                  )}
                                </Col>
                              </Row>
                              <br />
                              <Row
                                className="justify-content-center"
                                style={{ minHeight: '300px' }}
                              >
                                <Col md="auto">
                                  {componentSwitch(manualsState, switcher =>
                                    switcher
                                      .case(ObjectState.HIDDEN, () => null)
                                      .case(ObjectState.LOADING, () => (
                                        <Col>
                                          <Spinner
                                            animation="border"
                                            role="status"
                                          />
                                        </Col>
                                      ))
                                      .case(ObjectState.SHOWN, () => (
                                        <ManualTable manuals={manuals} />
                                      ))
                                      .case(ObjectState.ERROR, () => (
                                        <Col>
                                          <p>
                                            Unable to load manual information...
                                          </p>
                                        </Col>
                                      ))
                                  )}
                                </Col>
                              </Row>
                            </>
                          ) : null}
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                </Container>
              </div>
            ) : (
              <>{setProductState(ObjectState.ERROR)}</>
            )
          )
          .case(ObjectState.ERROR, () => (
            <Row className="align-content-center" style={{ height: '80vh' }}>
              <Col>
                <p>Unable to load product information at this time...</p>
              </Col>
            </Row>
          ))
      )}
    </div>
  )
}
