import React, { FC } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader
} from '@react-google-maps/api'
import { Button } from 'react-bootstrap'
import blue_marker from '../../images/blue_marker.png'
import red_marker from '../../images/red_marker.png'
import './Dealers.scss'
interface MapProps {
  dealers?: any[]
  userLocation?: GeolocationPosition
}

export const MyMap: FC<MapProps> = props => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_MAPS_API_KEY}`
  })

  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  const [activeDealer, setActiveDealer] = React.useState<any | null>()

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds()
      if (props.dealers) {
        props.dealers.forEach(dealer => {
          if (dealer.lat !== 0 && dealer.lng !== 0) {
            bounds.extend(
              new window.google.maps.LatLng({
                lat: dealer.lat,
                lng: dealer.lng
              })
            )
          }
        })
      }
      if (props.userLocation) {
        bounds.extend({
          lat: props.userLocation.coords.latitude,
          lng: props.userLocation.coords.longitude
        })
      }
      // Extends the Bounds so that the Zoom Level on fitBounds() is a bit farer away
      var extendBy = 0.005
      var point1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + extendBy,
        bounds.getNorthEast().lng() + extendBy
      )
      var point2 = new google.maps.LatLng(
        bounds.getSouthWest().lat() - extendBy,
        bounds.getSouthWest().lng() - extendBy
      )
      bounds.extend(point1)
      bounds.extend(point2)

      map.fitBounds(bounds)
      setMap(map)
    },
    [props.dealers]
  )

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const markerClicked = (e: google.maps.MapMouseEvent) =>
    console.log('marker clicked', e)

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        minWidth: '250px',
        minHeight: '400px',
        width: '100%',
        height: '100%',
        maxHeight: '700px'
      }}
      options={{ mapTypeControl: false, streetViewControl: false }}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={e => {
        setActiveDealer(null)
      }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {props.dealers
        ? props.dealers.map((dealer, i) =>
            dealer.lat !== 0 && dealer.lng !== 0 ? (
              <Marker
                key={i}
                position={{ lat: dealer.lat, lng: dealer.lng }}
                title={dealer.Company}
                clickable
                icon={red_marker}
                onClick={e => {
                  setActiveDealer(dealer)
                  markerClicked(e)
                }}
              ></Marker>
            ) : null
          )
        : null}
      {props.userLocation ? (
        <Marker
          position={{
            lat: props.userLocation.coords.latitude,
            lng: props.userLocation.coords.longitude
          }}
          title="Your Location"
          icon={blue_marker}
        ></Marker>
      ) : null}
      {activeDealer ? (
        <InfoWindow
          position={{ lat: activeDealer.lat, lng: activeDealer.lng }}
          options={{ minWidth: 120 }}
          onCloseClick={() => setActiveDealer(null)}
        >
          <div className="dealerInfoWindow">
            {activeDealer.Website ? (
              <a
                href={`http://${activeDealer.Website}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="DealerLogo"
                  alt={activeDealer.Logo}
                  src={`/dealer_logos/${activeDealer.Logo}`}
                />
                <p>View Website</p>
              </a>
            ) : (
              <img
                className="DealerLogo"
                alt={activeDealer.Logo}
                src={`/dealer_logos/${activeDealer.Logo}`}
              />
            )}
            <p>
              <span>{activeDealer.Company}</span>
              <br />
              {activeDealer.Address1 ? (
                <>
                  {activeDealer.Address1}
                  <br />
                </>
              ) : null}
              {activeDealer.Address2 ? (
                <>
                  {activeDealer.Address2} <br />
                </>
              ) : null}
              {activeDealer.Address3 ? (
                <>
                  {activeDealer.Address3} <br />
                </>
              ) : null}
              {activeDealer.Address4 ? (
                <>
                  {activeDealer.Address4} <br />
                </>
              ) : null}
            </p>
            {activeDealer.map_link ? (
              <>
                <a
                  href={activeDealer.map_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="primary">View in Maps</Button>
                </a>
                <br />
                <br />
              </>
            ) : null}
          </div>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  ) : (
    <></>
  )
}

// export const GoogleMap: FC<MapProps> = ({
//   onClick,
//   onIdle,
//   children,
//   style,
//   ...options
// }) => {
//   const ref = React.useRef<HTMLDivElement>(null)
//   const [map, setMap] = React.useState<google.maps.Map>()

//   React.useEffect(() => {
//     if (ref.current && !map) {
//       setMap(new window.google.maps.Map(ref.current, {}))
//     }
//   }, [ref, map])

//   // because React does not do deep comparisons, a custom hook is used
//   // see discussion in https://github.com/googlemaps/js-samples/issues/946
//   useDeepCompareEffectForMaps(() => {
//     if (map) {
//       map.setOptions(options)
//     }
//   }, [map, options])

//   React.useEffect(() => {
//     if (map) {
//       ;['click', 'idle'].forEach(eventName =>
//         google.maps.event.clearListeners(map, eventName)
//       )

//       if (onClick) {
//         map.addListener('click', onClick)
//       }

//       if (onIdle) {
//         map.addListener('idle', () => onIdle(map))
//       }
//     }
//   }, [map, onClick, onIdle])

//   return (
//     <>
//       <div ref={ref} style={style} />
//       {React.Children.map(children, child => {
//         if (React.isValidElement(child)) {
//           // set the map prop on the child component
//           return React.cloneElement(child, { map })
//         }
//       })}
//     </>
//   )
// }

// interface MarkerProps extends google.maps.MarkerOptions {
//   content: any
//   onClick?: (e: google.maps.MapMouseEvent) => void
// }

// export const Marker: React.FC<MarkerProps> = ({
//   content,
//   onClick,
//   ...options
// }) => {
//   const [marker, setMarker] = React.useState<google.maps.Marker>()

//   React.useEffect(() => {
//     if (!marker) {
//       setMarker(new google.maps.Marker())
//     }

//     // remove marker from map on unmount
//     return () => {
//       if (marker) {
//         marker.setMap(null)
//       }
//     }
//   }, [marker])

//   React.useEffect(() => {
//     if (marker) {
//       ;['click', 'idle'].forEach(eventName =>
//         google.maps.event.clearListeners(marker, eventName)
//       )
//     }
//   }, [marker, onClick])

//   React.useEffect(() => {
//     if (marker) {
//       marker.setOptions(options)
//     }
//   }, [marker, options])

//   return null
// }

// interface InfoWindowProps extends google.maps.InfoWindowOpenOptions {}

// export const InfoWindow: React.FC<InfoWindowProps> = ({ ...options }) => {
//   const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>()

//   React.useEffect(() => {
//     if (!infoWindow) {
//       setInfoWindow(new google.maps.InfoWindow())
//     }

//     // remove info from map
//     return () => {}
//   }, [infoWindow])

//   return null
// }

// const deepCompareEqualsForMaps = createCustomEqual(
//   deepEqual => (a: any, b: any) => {
//     if (
//       isLatLngLiteral(a) ||
//       a instanceof google.maps.LatLng ||
//       isLatLngLiteral(b) ||
//       b instanceof google.maps.LatLng
//     ) {
//       return new google.maps.LatLng(a).equals(new google.maps.LatLng(b))
//     }

//     // TODO extend to other types

//     // use fast-equals for other objects
//     return deepEqual(a, b)
//   }
// )

// function useDeepCompareMemoize(value: any) {
//   const ref = React.useRef()

//   if (!deepCompareEqualsForMaps(value, ref.current)) {
//     ref.current = value
//   }

//   return ref.current
// }

// function useDeepCompareEffectForMaps(
//   callback: React.EffectCallback,
//   dependencies: any[]
// ) {
//   React.useEffect(callback, dependencies.map(useDeepCompareMemoize))
// }
